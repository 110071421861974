import React from 'react'
import type { FC } from 'react'
import { TextStyle, View, ViewStyle } from 'react-native'

type SvgIconProps = {
  svg: NodeRequire | object
  size: number | { height: number; width: number }
  color?: string
  style?: ViewStyle
  accessibilityLabel?: string
  stroke?: string
  strokeWidth?: number
}

const SvgIcon: FC<SvgIconProps> = ({ svg, size, color, style, accessibilityLabel, stroke, strokeWidth, ...props }) => {
  if ('default' in svg && svg.default) svg = svg.default
  const Svg = svg as FC<{
    width: number
    height: number
    style: TextStyle
    stroke?: string
    strokeWidth?: number
  }>
  if (!accessibilityLabel?.length) accessibilityLabel = undefined

  const width = typeof size === 'number' ? size : size.width
  const height = typeof size === 'number' ? size : size.height
  // LTR direction for GIF svg on ReplyModal. Might want to revisit and make it optional?
  return (
    <View {...props} style={style} accessibilityLabel={accessibilityLabel}>
      <Svg
        width={width}
        height={height}
        stroke={stroke}
        strokeWidth={strokeWidth || 0}
        style={{ color, direction: 'ltr' }}
      />
    </View>
  )
}

export default SvgIcon
