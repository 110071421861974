import PropTypes from 'prop-types'
import React, { useRef, useEffect } from 'react'
import { View, TouchableOpacity, Text, Animated } from 'react-native'

import CCModal from './CCModal'
import ModalPortal from './ModalPortal'
import { modals, staticRefreshContainer } from './ModalsContainer'
import { stylesheetColors, ThemedStyleSheet } from '../../colors'
import { MODAL_ALERT_ZINDEX } from '../../misc/zIndexes'

const ANIMATION_TIME = 150

AlertModal.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
}
function AlertModal(props) {
  const keyRef = useRef(Math.random())
  return (
    <ModalPortal isOpen={props.isOpen}>
      <AlertModalContent keyRef={keyRef.current} {...props} />
    </ModalPortal>
  )
}
export default React.memo(AlertModal)

AlertModalContent.propTypes = {
  ...AlertModal.propTypes,
}
function AlertModalContent({ title, desc, options, onRequestClose, children }) {
  // Animate opening and closing
  const openPercentage = useRef(new Animated.Value(0))
  useEffect(() => {
    Animated.timing(openPercentage.current, {
      toValue: 1,
      duration: ANIMATION_TIME,
      useNativeDriver: true,
    }).start()
  }, [])

  const animatedClose = () => {
    Animated.timing(openPercentage.current, {
      toValue: 0,
      duration: ANIMATION_TIME,
      useNativeDriver: true,
    }).start(onRequestClose)
  }

  const bgRef = useRef()

  const optionsArray = Object.entries(options)
  return (
    <Animated.View style={[styles.root, { opacity: openPercentage.current }]}>
      <CCModal onRequestClose={animatedClose}>
        <View
          style={styles.container}
          onLayout={e => (bgRef.current = e.nativeEvent.target)}
          onStartShouldSetResponder={e => {
            if (e.nativeEvent.target === bgRef.current) animatedClose()
            return false
          }}>
          <View style={styles.content}>
            {title ? (
              <Text style={[styles.textTitle, styles.textTitlePadding]}>{title}</Text>
            ) : (
              <View style={styles.textTitlePadding} />
            )}
            {desc ? <Text style={styles.textDesc}>{desc}</Text> : null}
            {children}
            <View style={styles.separator} />
            <View style={styles.optionsContainer}>
              {optionsArray.map(([optionName, option]) => {
                return (
                  <TouchableOpacity
                    key={optionName}
                    style={styles.item}
                    onPress={async () => {
                      const handlerEvent = {
                        preventModalClose: () => {
                          handlerEvent.modalClosePrevented = true
                        },
                        modalClosePrevented: false,
                      }
                      await option.handler(handlerEvent)
                      if (!handlerEvent.modalClosePrevented) animatedClose()
                    }}>
                    <Text
                      style={[
                        styles.itemText,
                        option.destructive && styles.itemTextDestructive,
                        option.secondary && styles.itemTextSecondary,
                      ]}>
                      {optionName}
                    </Text>
                  </TouchableOpacity>
                )
              })}
            </View>
          </View>
        </View>
      </CCModal>
    </Animated.View>
  )
}

export function openAlertModal(title, desc, options, onClose) {
  const modalID = Math.random()
  modals[modalID] = (
    <AlertModalContent key={modalID} title={title} desc={desc} options={options} onRequestClose={closeModal} />
  )
  staticRefreshContainer()
  function closeModal() {
    delete modals[modalID]
    staticRefreshContainer()
    if (onClose) onClose()
  }
}

const styles = ThemedStyleSheet.create({
  root: {
    ...ThemedStyleSheet.absoluteFillObject,
    zIndex: MODAL_ALERT_ZINDEX,
  },
  container: {
    flexGrow: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  content: {
    alignSelf: 'center',
    width: '90%',
    maxWidth: 500,
    backgroundColor: stylesheetColors.white,
    borderRadius: 5,
    paddingTop: 15,
  },
  textTitle: {
    fontSize: 20,
    textAlign: 'center',
  },
  textTitlePadding: {
    paddingBottom: 15,
  },
  textDesc: {
    paddingBottom: 30,
    paddingHorizontal: 15,
    textAlign: 'center',
  },
  optionsContainer: {
    flexDirection: 'row',
  },
  item: {
    flexGrow: 1,
    flexBasis: 0,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  itemText: {
    textAlign: 'center',
    fontSize: 14,
  },
  itemTextDestructive: {
    color: stylesheetColors.red,
  },
  itemTextSecondary: {
    color: stylesheetColors.textLightGrey,
  },
  separator: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: stylesheetColors.lightGrey,
  },
})
