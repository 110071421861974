import AsyncStorage from './asyncStorage'
import { changeLang } from './i18n'
import detectUserDeviceLang from './i18n/detectUserDeviceLang'

export let userData: {
  id?: number
  username: string
  lang: string
  email?: string
  created_at?: number
  can_receive_gifts?: boolean
  country?: string
} | null = null

export async function clearUserData() {
  await Promise.all([AsyncStorage.removeItem('user'), AsyncStorage.removeItem('user_settings')])
  userData = null
  userSettings = Object.assign({}, defaultUserSettings)
}

export async function loadUserData() {
  const savedString = await AsyncStorage.getItem('user')
  if (!savedString) {
    changeLang(detectUserDeviceLang(), { userInteraction: false })
    return
  }
  try {
    userData = JSON.parse(savedString)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_) {
    userData = null
  }
  changeLang(userData?.lang || detectUserDeviceLang(), { userInteraction: false })
  await loadUserSettings()
}

export async function saveUserData(newUserData: typeof userData) {
  userData = newUserData
  await AsyncStorage.setItem('user', JSON.stringify(userData))
}

const defaultUserSettings: {
  allowanon?: boolean
  show_facebook?: boolean
  show_twitter?: boolean
  hidefollowstats?: boolean
  only_account?: boolean
  only_account_older_week?: boolean
  only_account_older_month?: boolean
  profanity_filter_moderate?: boolean
  profanity_filter_strict?: boolean
  daily_questions_optout?: boolean
  unfollow_notifications_optout?: boolean
  tw_share_hashtag?: boolean
  pushnotf_NewQuestions?: boolean
  pushnotf_QuestionAnswered?: boolean
  pushnotf_Follow?: boolean
  gifts_enabled?: boolean
  payout_country?: string
} = {
  allowanon: true,
  show_facebook: false,
  show_twitter: true,
  hidefollowstats: false,
  only_account: false,
  only_account_older_week: false,
  only_account_older_month: false,
  profanity_filter_moderate: true,
  profanity_filter_strict: false,
  daily_questions_optout: false,
  unfollow_notifications_optout: false,
  tw_share_hashtag: false,
  pushnotf_NewQuestions: true,
  pushnotf_QuestionAnswered: true,
  pushnotf_Follow: false,
  gifts_enabled: true,
  payout_country: 'AF',
}

export let userSettings = Object.assign({}, defaultUserSettings)

export async function loadUserSettings() {
  const savedString = await AsyncStorage.getItem('user_settings')
  if (!savedString) return
  try {
    userSettings = JSON.parse(savedString)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_) {
    userSettings = {}
  }
  userSettings = {
    ...defaultUserSettings,
    ...userSettings,
  }
}

export async function saveUserSettings(newUserSettings: typeof userSettings) {
  userSettings = {
    ...defaultUserSettings,
    ...userSettings,
    ...newUserSettings,
  }
  await AsyncStorage.setItem('user_settings', JSON.stringify(userSettings))
}
