import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { FETCH_PROFILE, RESET_PROFILE, PROFILE_DELETE_ITEM, PROFILE_UPDATE } from '../actions/types'
import preparePostListItemForState from '../preparePostListItemForState'

const initialState = {
  posts: {},
  profiles: {},
  usernamesToIDs: {},
  maxTimestampFromUsernames: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_PROFILE: {
      const newPostListItems = action.posts.map(preparePostListItemForState)
      const maxTimestamp = Math.min(...newPostListItems.map(item => item.timestamp))

      return Object.assign({}, state, {
        usernamesToIDs: Object.assign({}, state.usernamesToIDs, {
          [action.profile.username.toLowerCase()]: action.profile.id,
        }),
        maxTimestampFromUsernames: Object.assign({}, state.maxTimestampFromUsernames, {
          [action.profile.username.toLowerCase()]: maxTimestamp,
        }),
        profiles: Object.assign({}, state.profiles, {
          [action.profile.id]: action.profile,
        }),
        posts: Object.assign({}, state.posts, {
          [action.profile.id]: newPostListItems,
        }),
      })
    }

    case FETCH_PROFILE: {
      let newPostListItems = [
        ...(state.posts[action.profile.id] ?? []),
        ...action.posts.map(preparePostListItemForState),
      ]
      newPostListItems = newPostListItems.filter((item, index, arr) => arr.findIndex(p => p.key === item.key) === index)
      const maxTimestamp = Math.min(...newPostListItems.map(item => item.timestamp))

      return Object.assign({}, state, {
        usernamesToIDs: Object.assign({}, state.usernamesToIDs, {
          [action.profile.username.toLowerCase()]: action.profile.id,
        }),
        maxTimestampFromUsernames: Object.assign({}, state.maxTimestampFromUsernames, {
          [action.profile.username.toLowerCase()]: maxTimestamp,
        }),
        profiles: Object.assign({}, state.profiles, {
          [action.profile.id]: action.profile,
        }),
        posts: Object.assign({}, state.posts, {
          [action.profile.id]: newPostListItems,
        }),
      })
    }

    case PROFILE_UPDATE: {
      const newProfile = Object.assign({}, state.profiles[action.userID], action.changes)

      return Object.assign({}, state, {
        profiles: Object.assign({}, state.profiles, {
          [action.userID]: newProfile,
        }),
      })
    }

    case PROFILE_DELETE_ITEM: {
      if (!state.posts[action.userID]) return state

      const newPostListItems = state.posts[action.userID].filter(item => {
        if (item.type === 'post' || item.type === 'shared_post') return item.postID !== action.postID
        return true
      })

      return Object.assign({}, state, {
        posts: Object.assign({}, state.posts, {
          [action.userID]: newPostListItems,
        }),
      })
    }

    default:
      return state
  }
}

export function getMaxTimestampFromUsername(state, username) {
  username = username.toLowerCase()
  return state.profiles.maxTimestampFromUsernames[username] || undefined
}

export function useGetProfilePosts(username = '') {
  username = username.toLowerCase()
  const posts = useSelector(state => {
    const profileID = state.profiles.usernamesToIDs[username]
    return state.profiles.posts[profileID]
  })

  return useCallback(() => posts || [], [posts])
}

export function useProfiles() {
  return useSelector(state => state.profiles.profiles)
}

export function useProfileFromUsername(username) {
  return useSelector(state => state.profiles.profiles[state.profiles.usernamesToIDs[username.toLowerCase()]])
}
