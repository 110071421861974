import { t } from './i18n'
import { openAlertModal } from '../components/modals/AlertModal'

export function ccAlert(title: string | Error, message: string | undefined = undefined) {
  let titleText = title instanceof Error ? title.message : title ?? ''

  if (!message) {
    message = titleText
    titleText = ''
  }

  openAlertModal(titleText, message, {
    [t('Ok')]: {
      handler: () => {
        // Do nothing, just close
      },
    },
  })
}

export function ccConfirmAsync(title: string, { okText, cancelText }: { okText?: string; cancelText?: string } = {}) {
  return new Promise(resolve => {
    openAlertModal(
      '',
      title,
      {
        [cancelText || t('Cancel')]: {
          secondary: true,
          handler: () => {
            resolve(false)
          },
        },
        [okText || t('Ok')]: {
          handler: () => {
            resolve(true)
          },
        },
      },
      () => resolve(false)
    )
  })
}
