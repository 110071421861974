import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { modals, staticRefreshContainer } from './ModalsContainer'

ModalPortal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
}
function ModalPortal({ isOpen, children }) {
  const modalID = useRef(Math.random())

  useEffect(() => {
    if (isOpen) {
      modals[modalID.current] = children
    } else {
      delete modals[modalID.current]
    }
    staticRefreshContainer()
  }, [children, isOpen])

  // Failsafe in case <ModalPortal/> gets unmounted before isOpen changes to false.
  useEffect(() => {
    const modalIDSaved = modalID.current
    return () => {
      delete modals[modalIDSaved]
      staticRefreshContainer()
    }
  }, [])

  return null
}
export default React.memo(ModalPortal)
