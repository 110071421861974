import { locale } from 'expo-localization'
import { Platform } from 'react-native'

import { getUserAgent } from './appVersion'
import asyncStorage from '../lib/asyncStorage'
import getMainDomain from '../lib/getMainDomain'
import { ccNavigation } from '../lib/navUtils'
import { userData } from '../lib/user'

export function pageview(pathname) {
  const isWeb = Platform.OS === 'web'
  const navState = ccNavigation && ccNavigation.getState()
  const routeName = navState ? getActiveRouteName(navState) : ''

  let path
  if (isWeb) {
    path = document.location.href
  } else {
    try {
      path = `https://${getMainDomain()}${pathname}`
    } catch (_) {}
  }

  const pageviewUrl =
    getBaseUrl() +
    getOptionalStr('dt', routeName) +
    getOptionalStr('dl', path) +
    getOptionalStr('sd', isWeb && window.screen.colorDepth + '-bit') +
    getOptionalStr('sr', isWeb && window.screen.availWidth + 'x' + window.screen.availHeight) +
    getOptionalStr('vp', isWeb && window.innerWidth + 'x' + window.innerHeight) +
    getOptionalStr('dr', isWeb && document.referrer)

  const options = { headers: {} }
  if (Platform.OS !== 'web') options.headers['User-Agent'] = getUserAgent()

  return fetch(pageviewUrl, options).catch(() => {
    /* Ignore error */
  })
}

function getActiveRouteName(state) {
  const route = state?.routes[state?.index ?? 0]

  // if (route.state) {
  //   // Dive into nested navigators
  //   return getActiveRouteName(route.state)
  // }

  return route?.name || ''
}

function getOptionalStr(key, value) {
  if (!key || !value) return ''
  return '&' + key + '=' + encodeURIComponent(value)
}

let gaClientID
const CLIENT_ID_KEY = 'ga_uid' // ga_uid is a bad name for this key but oh well
asyncStorage
  .getItem(CLIENT_ID_KEY)
  .then(val => (gaClientID = val))
  .catch(() => {
    /* Ignore error */
  })
function getClientId() {
  if (!gaClientID) {
    gaClientID = `${Math.random().toString().slice(2)}.${Date.now()}`

    asyncStorage.setItem(CLIENT_ID_KEY, gaClientID)
  }

  return gaClientID
}
function getUserID() {
  if (userData) return userData.ga_uid
  return null
}

function getBaseUrl() {
  return (
    'https://www.google-analytics.com/collect' +
    '?v=1' +
    '&de=UTF-8' +
    '&t=pageview' +
    '&tid=UA-76203074-1' +
    `&cid=${getClientId()}` +
    (getUserID() ? `&uid=${getUserID()}` : '') +
    `&z=${Date.now()}` +
    `&ul=${locale}`
  )
}
