// This function already exists on web as `window.btoa`, but this is needed for native devices

export default function base64Encode(input: string) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  const str = input
  let output = ''

  let block = 0
  let charCode
  let i = 0
  for (
    let map = chars;
    // eslint-disable-next-line no-bitwise
    str.charAt(i | 0) || ((map = '='), i % 1);
    // eslint-disable-next-line no-bitwise
    output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
  ) {
    charCode = str.charCodeAt((i += 3 / 4))

    if (charCode > 0xff) {
      throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.")
    }

    // eslint-disable-next-line no-bitwise
    block = (block << 8) | charCode
  }

  return output
}
