import PropTypes from 'prop-types'
import React from 'react'
import { View, ActivityIndicator } from 'react-native'

import { ThemedStyleSheet } from '../../colors'
import RoundedBgModal from '../modals/RoundedBgModal'

CCFullscreenActivityIndicator.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
}
export default function CCFullscreenActivityIndicator({ isOpen, onRequestClose }) {
  if (!onRequestClose) {
    onRequestClose = () => {
      // Do nothing
    }
  }

  return (
    <RoundedBgModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <View style={styles.wrapper}>
        <ActivityIndicator size="large" />
      </View>
    </RoundedBgModal>
  )
}

const styles = ThemedStyleSheet.create({
  wrapper: {
    padding: 30,
  },
})
