import { useEffect, useState } from 'react'

export let reloadApp = () => {
  /* This will be replaced with a function with an actual body once app is mounted  */
}

export function useShouldRenderAppDueToAppReload() {
  const [reloading, setReloading] = useState(false)

  reloadApp = () => setReloading(true)

  useEffect(() => {
    if (!reloading) return
    setReloading(false)
  }, [reloading])

  return !reloading
}
