import * as WebBrowser from 'expo-web-browser'
import { Linking, Platform } from 'react-native'

export function openLink(url, useInappModal = true) {
  if (Platform.OS === 'web') {
    window.open(url, '_blank')
    return
  }
  if (useInappModal) return WebBrowser.openBrowserAsync(url)
  return Linking.openURL(url)
}
