import { useState, useEffect } from 'react'

import { t } from '../../lib/i18n'

export const MENU_ITEMS = [
  {
    routeName: 'Home',
    routerRoutes: ['Home', 'FakeEmptyFeedForTutorial'],
    accessibilityLabel: t('Home'),
    icon: require('./img/home.svg'),
  },
  {
    routeName: 'Earnings',
    routerRoutes: ['Earnings'],
    accessibilityLabel: t('Earnings'),
    icon: require('./img/earnings.svg'),
  },
  {
    routeName: 'Profile',
    routerRoutes: ['Profile', 'ProfilePost', 'ProfileEdit'],
    accessibilityLabel: t('Profile'),
    icon: require('./img/profile.svg'),
  },
  {
    routeName: 'Inbox',
    routerRoutes: ['Inbox'],
    accessibilityLabel: t('Inbox'),
    icon: require('./img/inbox.svg'),
  },
  {
    routeName: 'Notifications',
    routerRoutes: ['Notifications'],
    accessibilityLabel: t('Notifications'),
    icon: require('./img/notifications.svg'),
  },
]

// Static inbox + notif bubble setters
const rerenderHandlers = []
const staticCounts = { inbox: 0, notifications: 0 }
export function menuNavItemsSetCounts({ inbox, notifications }) {
  staticCounts.inbox = inbox
  staticCounts.notifications = notifications

  rerenderHandlers.forEach(cb => cb())
}

export function useStaticCounts() {
  const [, _reload] = useState()
  useEffect(() => {
    const handler = () => _reload({})
    rerenderHandlers.push(handler)
    return () => {
      rerenderHandlers.splice(rerenderHandlers.indexOf(handler), 1)
    }
  }, [])
  return staticCounts
}
