import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, TouchableOpacity } from 'react-native'

import { ThemedStyleSheet, stylesheetColors, renderColors } from '../../colors'
import layout, { useOnDimensionsChange } from '../../lib/layout'
import { TUTORIAL_TIP_PANEL_ZINDEX } from '../../misc/zIndexes'
import SvgIcon from '../SvgIcon'

TipPanel.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  progressString: PropTypes.string.isRequired,
  onSkipPressed: PropTypes.func.isRequired,
  onNextPressed: PropTypes.func.isRequired,
  containerPos: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
  }).isRequired,
  arrowXPos: PropTypes.number.isRequired, // [0-100] in % of width
  invertedArrow: PropTypes.bool.isRequired,
}
export default function TipPanel({
  title,
  text,
  progressString,
  onSkipPressed,
  onNextPressed,
  containerPos,
  invertedArrow,
  arrowXPos,
}) {
  const [t] = useTranslation()
  useOnDimensionsChange()

  const diamondLeft = (arrowXPos / 100) * layout.drawableWidth - ARROW_SVG_SIZE / 2
  const diamondElm = (
    <SvgIcon
      svg={require('./img/diamond.svg')}
      accessibilityLabel=""
      size={ARROW_SVG_SIZE}
      style={[invertedArrow ? styles.arrowSvgTop : styles.arrowSvgBottom, { left: diamondLeft }]}
      color={renderColors.orange}
    />
  )

  return (
    <View
      style={[styles.wrapper, { maxWidth: layout.drawableWidth, top: containerPos.top, bottom: containerPos.bottom }]}>
      {invertedArrow && diamondElm}
      <View style={styles.container}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={styles.title}>{title}</Text>
          <TouchableOpacity style={{ padding: 10 }} onPress={onSkipPressed}>
            <Text style={styles.skip}>{t('Skip')}</Text>
          </TouchableOpacity>
        </View>
        <Text style={styles.text}>{text}</Text>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={styles.progressString}>{progressString}</Text>
          <TouchableOpacity style={{ padding: 10 }} onPress={onNextPressed}>
            <Text style={styles.next}>{t('Next')}</Text>
          </TouchableOpacity>
        </View>
      </View>
      {!invertedArrow && diamondElm}
    </View>
  )
}

const ARROW_SVG_SIZE = 25
const styles = ThemedStyleSheet.create({
  wrapper: {
    position: 'absolute',
    width: '100%',
    alignSelf: 'center',
    zIndex: TUTORIAL_TIP_PANEL_ZINDEX,
  },
  container: {
    position: 'relative', // Not sure if needed, but just in case, to always show up above diamond
    backgroundColor: stylesheetColors.orange,
    paddingHorizontal: 10,
  },
  title: {
    color: '#FFF',
    fontWeight: '500',
    flexGrow: 1,
  },
  skip: {
    color: '#FFF',
    fontSize: 14,
  },
  text: {
    color: '#FFF',
  },
  progressString: {
    color: '#FFF',
    flexGrow: 1,
  },
  next: {
    fontWeight: '500',
    color: '#FFF',
  },
  arrowSvgBottom: {
    marginTop: -ARROW_SVG_SIZE / 2,
  },
  arrowSvgTop: {
    marginBottom: -ARROW_SVG_SIZE / 2,
  },
})
