const translationStrings = {
  ar_SA: require(/* webpackChunkName: "translations" */ './translations/ar_SA.json'),
  en_US: require(/* webpackChunkName: "translations" */ './translations/en_US.json'),
  es_ES: require(/* webpackChunkName: "translations" */ './translations/es_ES.json'),
  fr_FR: require(/* webpackChunkName: "translations" */ './translations/fr_FR.json'),
  it_IT: require(/* webpackChunkName: "translations" */ './translations/it_IT.json'),
  ko_KR: require(/* webpackChunkName: "translations" */ './translations/ko_KR.json'),
  pt_BR: require(/* webpackChunkName: "translations" */ './translations/pt_BR.json'),
  tr_TR: require(/* webpackChunkName: "translations" */ './translations/tr_TR.json'),
}
export default translationStrings

export const languages = {
  en_US: 'English',
  ar_SA: 'العَرَبِيَّة',
  es_ES: 'Español',
  fr_FR: 'Français',
  it_IT: 'Italiano',
  ko_KR: '한국어',
  pt_BR: 'Português',
  tr_TR: 'Türkçe',
}
