import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, Text } from 'react-native'

import { ccAlert } from '../../lib/alerts'
import api from '../../lib/api'
import { userData } from '../../lib/user'
import UserInList, { UserInListPlaceholder } from '../UserInList'
import ScreenModal from '../modals/ScreenModal'

FindFriendsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
}
export default function FindFriendsModal({ isOpen, onRequestClose }) {
  const [t] = useTranslation()

  return (
    <ScreenModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t('Find Friends')}
      closeType="back"
      hasSeparator>
      <FindFriends onRequestClose={onRequestClose} />
    </ScreenModal>
  )
}

FindFriends.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
}
function FindFriends({ onRequestClose }) {
  const [t] = useTranslation()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [isDiscoverUsers, setIsDiscoverUsers] = useState(false)

  const loadDiscoverUsers = useCallback(() => {
    api.get('/v2/discover/users').then(res => {
      setIsDiscoverUsers(true)
      setUsers(res.users)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const promises = []
    if (userData.twitterlinked) promises.push(api.get('/v2/discover/from_twitter'))
    if (userData.facebooklinked) promises.push(api.get('/v2/discover/from_facebook'))
    Promise.all(promises)
      .then(responses => {
        const newUsers = responses.map(res => res.users).flat()
        if (newUsers.length) {
          setUsers(newUsers)
          setLoading(false)
        } else {
          loadDiscoverUsers()
        }
      })
      .catch(err => {
        ccAlert(err.message)
        setLoading(false)
      })
  }, [loadDiscoverUsers])

  return (
    <ScrollView>
      {isDiscoverUsers && (
        <Text style={{ textAlign: 'center', padding: 15 }}>
          {t('Looks like none of your friends are on CuriousCat yet!') +
            ' ' +
            t('But here are some profiles you might find interesting:')}
        </Text>
      )}
      {loading
        ? new Array(9).fill(null).map(() => {
            return <UserInListPlaceholder key={Math.random()} />
          })
        : users.map(user => {
            return <UserInList key={user.id} user={user} onRequestClose={onRequestClose} />
          })}
    </ScrollView>
  )
}
