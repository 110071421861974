import * as Updates from 'expo-updates'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Platform, I18nManager } from 'react-native'

import translationStrings, { languages } from './translationStrings'

export const t = (...props) => i18nInstance.t(...props)

export const getCurrentLang = () => {
  return i18nInstance.language
}

export const changeLang = (newLang, options = {}) => {
  if (!languages[newLang]) newLang = 'en_US' // Lang not found, fallback to english
  i18nInstance.changeLanguage(newLang)
  setRTL(newLang, options)
}

// TODO: Test RTL changing for all cases (device lang, changing in another device and reloading, etc)
const langsRTL = new Set(['ar_SA'])
const setRTL = (newLang, options) => {
  const oldIsRTL = I18nManager.isRTL
  const newIsRTL = langsRTL.has(newLang)
  if (oldIsRTL === newIsRTL) return
  I18nManager.forceRTL(newIsRTL)
  // We need to refresh to load the new direction
  if (Platform.OS === 'web') {
    if (options.userInteraction) window.location.reload()
  } else {
    Updates.reloadAsync()
  }
}

const resources = {}
Object.keys(translationStrings).forEach(langCode => {
  resources[langCode] = { translation: translationStrings[langCode] }
})

const i18nInstance = i18n.createInstance()
i18nInstance
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'en_US',
    fallbackLng: 'en_US',
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
