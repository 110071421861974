import PropTypes from 'prop-types'
import React, { useEffect, useRef, useCallback } from 'react'
import { View, Platform, BackHandler, Keyboard } from 'react-native'

import trapFocusUtil from './trapFocusUtil'
import { stylesheetColors, ThemedStyleSheet } from '../../colors'
import layout from '../../lib/layout'
import { MODAL_CCMODAL_CONTAINER_ZINDEX, MODAL_CCMODAL_BG_ZINDEX } from '../../misc/zIndexes'

CCModal.propTypes = {
  noChangeFocus: PropTypes.bool,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
}
function CCModal({ onRequestClose, children, noChangeFocus }) {
  const bgRef = useRef()

  // Change and Trap focus on web
  // TODO: When more than 1 modal is open, detect modal closing to return focus to last modal opened
  const onContainerLayout = useCallback(
    event => {
      const elm = event.nativeEvent.target
      if (Platform.OS === 'web' && elm?.focus) {
        trapFocusUtil(event.nativeEvent.target, { noChangeFocus })
      }
    },
    [noChangeFocus]
  )

  // Android Back key
  useEffect(() => {
    const handleCloseRequest = () => {
      onRequestClose()
      return true
    }
    BackHandler.addEventListener('hardwareBackPress', handleCloseRequest)
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleCloseRequest)
    }
  }, [onRequestClose])

  // Keyboard ESC key
  useEffect(() => {
    if (Platform.OS !== 'web') return

    const handleKeyPressed = evt => {
      if (evt.defaultPrevented) return
      evt.preventDefault()
      onRequestClose()
    }
    webEscKeyPressedHandlers.unshift(handleKeyPressed)
    return () => {
      const index = webEscKeyPressedHandlers.indexOf(handleKeyPressed)
      webEscKeyPressedHandlers.splice(index, 1)
    }
  }, [onRequestClose])

  // Dismiss keyboard if open upon modal opening
  // This shouldn't break autofocus on inputs inside the modals, since it's called before they're rendered
  useEffect(() => {
    Keyboard.dismiss()
  }, [])

  return (
    <View
      style={styles.modalBackground}
      onLayout={e => (bgRef.current = e.nativeEvent.target)}
      onStartShouldSetResponder={e => {
        if (e.nativeEvent.target === bgRef.current) onRequestClose()
        return false
      }}>
      <View style={styles.container} onLayout={onContainerLayout}>
        {children}
      </View>
    </View>
  )
}
export default React.memo(CCModal)

const styles = ThemedStyleSheet.create({
  container: {
    zIndex: MODAL_CCMODAL_CONTAINER_ZINDEX,
    position: 'absolute',
    top: 0,
    alignSelf: 'center',
    maxWidth: layout.DESKTOP_WIDTH_BREAKPOINT,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  modalBackground: {
    zIndex: MODAL_CCMODAL_BG_ZINDEX,
    ...ThemedStyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: stylesheetColors.modalSemiopaqueBackground,
  },
})

// Custom event system since they are executed in order of registration
// It would cause the very first modal to be closed
const webEscKeyPressedHandlers = []
if (Platform.OS === 'web') {
  window.document.addEventListener('keydown', evt => {
    if (evt.keyCode !== 27) return
    webEscKeyPressedHandlers.forEach(cb => cb(evt))
  })
}
