// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Image, type ImageProps } from 'expo-image'
import React, { useMemo, useRef } from 'react'
import { ImageStyle, processColor, View, ViewStyle } from 'react-native'

import { ThemedStyleSheet } from '../../colors'

interface Props extends ImageProps {
  webStyle?: ViewStyle
}

export default function CCImage({ placeholder, accessibilityLabel, webStyle, ...props }: Props) {
  // We need to set a placeholder on web, otherwise it crashes in some situations like opening a profile page. This should be fixed by Expo eventually
  // Just set a 1x1 transparent png as placeholder
  placeholder =
    placeholder ??
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

  // There is no tintColor support on web, use custom implementation
  const svgFilterId = useRef('ccimage-filter-' + Math.random().toString().slice(2))
  const tintColor = useMemo(
    () => props.tintColor ?? ThemedStyleSheet.flatten(props.style).tintColor,
    [props.style, props.tintColor]
  )
  const colorMatrixValues = useMemo(() => {
    if (tintColor === undefined) {
      return null
    }
    const processedColor = processColor(tintColor)
    if (processedColor === null || processedColor === undefined) {
      return null
    }
    const stringColor = ((processedColor as number) >>> 0).toString(16)

    const r = parseInt(stringColor.slice(2, 4), 16) / 255
    const g = parseInt(stringColor.slice(4, 6), 16) / 255
    const b = parseInt(stringColor.slice(6, 8), 16) / 255
    return `
0 0 0 0 ${r}
0 0 0 0 ${g}
0 0 0 0 ${b}
0 0 0 1 0`
  }, [tintColor])

  const newStyle = useMemo(() => {
    if (!colorMatrixValues) {
      return props.style
    }

    const filterStyle = { filter: `url(#${svgFilterId.current})` }
    return [props.style, filterStyle] as ImageStyle
  }, [colorMatrixValues, props.style])

  // Wrap in View with accessibilityLabel because expo-image does not implement alt right now: https://expo.canny.io/feature-requests/p/expo-image-alt-prop
  return (
    <View style={webStyle} accessibilityLabel={accessibilityLabel}>
      {colorMatrixValues && (
        <svg width="0" height="0" style={{ position: 'absolute', pointerEvents: 'none' }}>
          <defs>
            <filter id={svgFilterId.current}>
              <feColorMatrix type="matrix" colorInterpolationFilters="sRGB" values={colorMatrixValues} />
            </filter>
          </defs>
        </svg>
      )}
      <Image {...props} style={newStyle} placeholder={placeholder} />
    </View>
  )
}
