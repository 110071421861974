import { t } from '../../lib/i18n'
import layout, { isDesktop } from '../../lib/layout'
import { ccNavigation, goToProfile } from '../../lib/navUtils'
import { userData } from '../../lib/user'
import { scrollToBottomAllPaginationLists } from '../designSystem/scrollToBottomAllPaginationLists'

const MENU_POS = isDesktop ? { top: 70 } : { bottom: 85 } // Position to be next to menu
const MENU_INVERTED_ARROW = isDesktop
const MENU_FEED_XPOS = isDesktop ? 59.5 : 10
const MENU_PROFILE_XPOS = isDesktop ? 77.5 : 50
const MENU_INBOX_XPOS = isDesktop ? 86.5 : 70
const MENU_NOTIFICATIONS_XPOS = isDesktop ? 95.5 : 90
const REPLY_ARROW_XPOS = isDesktop ? 11 : 27
const REPLY_YPOS = isDesktop ? 240 : 280
const FINISHING_UP_POS = isDesktop ? { top: 480 } : { bottom: 250 }

export const tutorialScreens = [
  {
    title: t('This is your feed'),
    text: t(
      "All activity from the people you follow will show up here, and there's plenty of ways to interact with them!"
    ),
    containerPos: MENU_POS,
    arrowXPos: MENU_FEED_XPOS,
    invertedArrow: MENU_INVERTED_ARROW,
    beforeMount() {
      ccNavigation.navigate('Home')
    },
  },
  {
    title: t('Find Friends'),
    text: t("Let's see if any of your friends are already using CuriousCat... Press Next to continue"),
    containerPos: { top: layout.drawableHeight / 2 + 130 },
    arrowXPos: 50,
    invertedArrow: true,
  },
  {
    title: t('Welcome to your profile'),
    text: t(
      'This is your public profile, all the questions you answer will show up here, you can edit it later if you wish.'
    ),
    containerPos: MENU_POS,
    arrowXPos: MENU_PROFILE_XPOS,
    invertedArrow: MENU_INVERTED_ARROW,
    beforeMount() {
      openFindFriendsModal() // From last tutorial screen
      goToProfile(userData.username)
    },
  },
  {
    title: t('This is your notification center'),
    text: t(
      "All activity from the people you follow will show up here, and there's plenty of ways to interact with them!"
    ),
    containerPos: MENU_POS,
    arrowXPos: MENU_NOTIFICATIONS_XPOS,
    invertedArrow: MENU_INVERTED_ARROW,
    beforeMount() {
      ccNavigation.navigate('Notifications')
    },
  },
  {
    title: t('This is your inbox'),
    text: t('All the questions you get will show up here, and this is where you will come to reply to them'),
    containerPos: MENU_POS,
    arrowXPos: MENU_INBOX_XPOS,
    invertedArrow: MENU_INVERTED_ARROW,
    beforeMount() {
      ccNavigation.navigate('Inbox')
    },
  },
  {
    title: t('Replying'),
    text: t(
      'If you want to reply to any question on CuriousCat, you just have to tap here (you may also share your reply)'
    ),
    containerPos: { top: REPLY_YPOS },
    arrowXPos: REPLY_ARROW_XPOS,
    invertedArrow: true,
  },
  {
    title: t('Finishing up.'),
    text: t(
      "That's it! You know the basics, now all you're missing is questions... Share your profile and let the fun begin!"
    ),
    containerPos: FINISHING_UP_POS,
    arrowXPos: 50,
    invertedArrow: false,
    beforeMount() {
      scrollToBottomAllPaginationLists()
    },
  },
]

let openFindFriendsModal = () => {
  /* Ignore error */
}
export function setOpenFindFriendsModal(newVal) {
  openFindFriendsModal = newVal
}
