import PropTypes from 'prop-types'
import React, { useImperativeHandle, useState } from 'react'
import { View } from 'react-native'

import CCImage from './designSystem/CCImage'
import { stylesheetColors, renderColors, ThemedStyleSheet } from '../colors'

const Avatar = React.forwardRef(AvatarReal)

Avatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.number.isRequired,
  style: PropTypes.any,
  children: PropTypes.node,
  borderWidth: PropTypes.number,
}
function AvatarReal({ user, children, size, borderWidth = Math.round(size / 11), style, ...props }, ref) {
  useImperativeHandle(ref, () => ({}))

  const [isLoaded, setIsLoaded] = useState(false)

  const containerStyle = [
    {
      width: size,
      height: size,
      borderRadius: size / 2,
      borderWidth,
      backgroundColor: isLoaded ? undefined : renderColors.lightGrey,
    },
    styles.avatarContainer,
    style,
  ]

  if (!user) {
    return (
      <View {...props} style={containerStyle}>
        {children}
      </View>
    )
  }

  const imgSize = size - borderWidth * 2
  return (
    <View {...props} style={containerStyle}>
      <View
        style={{
          width: imgSize,
          height: imgSize,
          borderRadius: imgSize * 0.5,
          overflow: 'hidden', // Wrapper needed since in android (+maybe ios) <Image> with GIFs do not respect borderRadius. This should be checked again since we moved from RN's Image to Expo's Image
        }}>
        <CCImage
          source={user ? { uri: user.avatar } : null}
          onLoad={() => setIsLoaded(true)}
          style={{
            width: imgSize,
            height: imgSize,
          }}
        />
      </View>
    </View>
  )
}

export default Avatar

const styles = ThemedStyleSheet.create({
  avatarContainer: {
    position: 'relative',
    borderColor: stylesheetColors.grey,
  },
})
