import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, TouchableOpacity } from 'react-native'

import { openNativeAppLink } from './links'
import { ThemedStyleSheet, stylesheetColors, renderColors } from '../../../colors'
import asyncStorage from '../../../lib/asyncStorage'
import layout, { useOnDimensionsChange } from '../../../lib/layout'
import SvgIcon from '../../SvgIcon'
import CCButton from '../../designSystem/CCButton'

export default function LikeAppStore() {
  const [lastHideUnixTs, setLastHideUnixTs] = useState(null)
  const doHide = useCallback(() => {
    const newValue = Math.floor(Date.now() / 1000)
    setLastHideUnixTs(newValue)
    asyncStorage.setItem('lastHideForLikeAppStore', newValue)
  }, [])

  useEffect(() => {
    async function main() {
      setLastHideUnixTs(await asyncStorage.getItem('lastHideForLikeAppStore', null))
    }
    main()
  }, [])

  useOnDimensionsChange()
  const [t] = useTranslation()
  const containerWidth = Math.min(layout.screenWidth - 30, layout.DESKTOP_WIDTH_BREAKPOINT)

  if (lastHideUnixTs !== null && Date.now() / 1000 - 60 * 60 * 24 * 7 < lastHideUnixTs) {
    return null
  }

  return (
    <View style={[styles.container, { width: containerWidth }]}>
      <View style={styles.logoContainer}>
        <SvgIcon svg={require('./img/logo.svg')} size={32} color={renderColors.orange} accessibilityLabel="" />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.textTitle}>CuriousCat</Text>
        <Text style={styles.textSubtitle}>{t('The official app')}</Text>
        <View style={{ flexDirection: 'row', marginTop: 5 }}>
          <SvgIcon
            style={{ marginRight: 3 }}
            svg={require('./img/star.svg')}
            size={10}
            color={renderColors.black}
            accessibilityLabel=""
          />
          <SvgIcon
            style={{ marginRight: 3 }}
            svg={require('./img/star.svg')}
            size={10}
            color={renderColors.black}
            accessibilityLabel=""
          />
          <SvgIcon
            style={{ marginRight: 3 }}
            svg={require('./img/star.svg')}
            size={10}
            color={renderColors.black}
            accessibilityLabel=""
          />
          <SvgIcon
            style={{ marginRight: 3 }}
            svg={require('./img/star.svg')}
            size={10}
            color={renderColors.black}
            accessibilityLabel=""
          />
          <SvgIcon svg={require('./img/star.svg')} size={10} color={renderColors.grey} accessibilityLabel="" />
        </View>
      </View>
      <View style={styles.buttonsContainer}>
        <CCButton
          title={t('Download')}
          type="orange"
          style={styles.createAccountButton}
          onPress={() => {
            openNativeAppLink('100-likeappstore')
            doHide()
          }}
        />
        <TouchableOpacity
          onPress={() => {
            doHide()
            asyncStorage.setItem('CTADownloadNativeAppDismissed', Math.floor(Date.now() / 1000))
          }}>
          <Text style={styles.textDismiss}>{t('Dismiss')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = ThemedStyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 35 + layout.bottomSafeSpace,
    alignSelf: 'center',
    backgroundColor: stylesheetColors.white,
    padding: 15,
    flexDirection: 'row',
    borderRadius: 2,
    // Shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  logoContainer: {
    padding: 9,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: stylesheetColors.grey,
    backgroundColor: stylesheetColors.lightGrey,
    marginRight: 10,
    alignSelf: 'center',
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  textTitle: {
    fontWeight: '500',
    fontSize: 16,
    marginBottom: 2,
  },
  textSubtitle: {
    color: stylesheetColors.textLightGrey,
    fontSize: 12,
  },
  buttonsContainer: {
    alignItems: 'center',
    justifyContent: 'space-around',
    marginLeft: 20,
  },
  createAccountButton: {
    paddingHorizontal: 15,
    borderRadius: 15,
  },
  textDismiss: {
    marginTop: 5,
    fontSize: 12,
    color: stylesheetColors.textLightGrey,
    borderBottomWidth: 1,
    borderBottomColor: stylesheetColors.textLightGrey,
  },
})
