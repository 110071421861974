import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Text, View, TouchableOpacity, Animated } from 'react-native'

import CCModal from './CCModal'
import ModalPortal from './ModalPortal'
import { stylesheetColors, renderColors, ThemedStyleSheet } from '../../colors'
import { t } from '../../lib/i18n'
import layout from '../../lib/layout'
import { useCloseOnNavigationChange } from '../../lib/navUtils'
import { MODAL_SCREEN_ZINDEX } from '../../misc/zIndexes'
import SvgIcon from '../SvgIcon'
import CCKeyboardAvoidingView from '../designSystem/CCKeyboardAvoidingView'

const ANIMATION_TIME = 150
ScreenModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  closeType: PropTypes.string,
  title: PropTypes.any.isRequired,
  children: PropTypes.element,
}
export default function ScreenModal(props) {
  const keyRef = useRef(Math.random())
  const [isModalPortalOpen, setIsModalPortalOpen] = useState(props.isOpen)

  useEffect(() => {
    if (props.isOpen && !isModalPortalOpen) setIsModalPortalOpen(true)
  }, [props.isOpen, isModalPortalOpen])

  return (
    <ModalPortal isOpen={isModalPortalOpen}>
      <ScreenModalContent
        {...props}
        key={keyRef.current}
        closeModalPortal={useCallback(() => setIsModalPortalOpen(false), [])}
      />
    </ModalPortal>
  )
}

ScreenModalContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  titleStyle: PropTypes.any,
  titleTextStyle: PropTypes.any, // Text.propTypes.style,
  closeType: PropTypes.oneOf(['close', 'back']),
  children: PropTypes.node,
  hasSeparator: PropTypes.bool,
  leftButton: PropTypes.node,
  rightButton: PropTypes.node,
  noChangeFocus: PropTypes.bool,
  closeModalPortal: PropTypes.func.isRequired,
}
function ScreenModalContent({
  isOpen,
  onRequestClose,
  title,
  titleStyle,
  titleTextStyle,
  closeType,
  children,
  hasSeparator,
  leftButton,
  rightButton,
  noChangeFocus,
  closeModalPortal,
}) {
  useCloseOnNavigationChange({ isOpen, onRequestClose })

  // Animate opening and closing
  const openPercentage = useRef(new Animated.Value(0))

  useEffect(() => {
    Animated.timing(openPercentage.current, {
      toValue: isOpen ? 1 : 0,
      duration: ANIMATION_TIME,
      useNativeDriver: true,
    }).start(isOpen ? undefined : closeModalPortal)
  }, [isOpen, closeModalPortal])

  const TitleFn = title
  const titleElm =
    typeof title === 'string' ? (
      <Text style={[styles.title, titleTextStyle]}>{title}</Text>
    ) : typeof title === 'function' ? (
      <TitleFn />
    ) : (
      title
    )

  return (
    <Animated.View style={[styles.root, { opacity: openPercentage.current }]}>
      <CCModal onRequestClose={onRequestClose} noChangeFocus={noChangeFocus}>
        <CCKeyboardAvoidingView style={styles.modalContainer}>
          <View style={styles.statsBarHeightSpacer} />
          <View style={[styles.titleContainer, titleStyle]}>
            {leftButton}
            {titleElm}
            {closeType === 'back' ? (
              <TouchableOpacity style={[styles.typeButton, styles.backButton]} onPress={onRequestClose}>
                <SvgIcon
                  svg={require('./screenModalImg/back.svg')}
                  size={20}
                  color={renderColors.black}
                  accessibilityLabel={t('Back')}
                />
              </TouchableOpacity>
            ) : closeType === 'close' ? (
              <TouchableOpacity style={[styles.typeButton, styles.closeButton]} onPress={onRequestClose}>
                <SvgIcon
                  svg={require('./screenModalImg/close.svg')}
                  size={15}
                  color={renderColors.black}
                  accessibilityLabel={t('Close')}
                />
              </TouchableOpacity>
            ) : null}
            {rightButton}
          </View>
          {hasSeparator && <View style={styles.separator} />}
          <View style={styles.contentContainer}>{children}</View>
        </CCKeyboardAvoidingView>
      </CCModal>
    </Animated.View>
  )
}

const styles = ThemedStyleSheet.create({
  root: {
    ...ThemedStyleSheet.absoluteFillObject,
    zIndex: MODAL_SCREEN_ZINDEX,
  },
  statsBarHeightSpacer: {
    height: layout.statusBarHeight,
    backgroundColor: stylesheetColors.white,
  },
  titleContainer: {
    backgroundColor: stylesheetColors.white,
    flexDirection: 'row',
  },
  typeButton: {
    position: 'absolute',
    alignSelf: 'center',
    padding: 15,
  },
  backButton: {
    left: 0,
  },
  closeButton: {
    right: 0,
  },
  title: {
    flexGrow: 1,
    padding: 15,
    textAlign: 'center',
    fontSize: 20,
  },
  separator: {
    borderBottomColor: stylesheetColors.lightGrey,
    borderBottomWidth: 1,
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 1,
    backgroundColor: stylesheetColors.white,
  },
})
