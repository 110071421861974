import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Text, TextInput, View } from 'react-native'

import { renderColors, stylesheetColors, ThemedStyleSheet } from '../../colors'
import { ccAlert } from '../../lib/alerts'
import api, { refreshUserData } from '../../lib/api'
import CCButton from '../designSystem/CCButton'
import RoundedBgModal from '../modals/RoundedBgModal'

const SetEmailModal = () => {
  const [isOpen, setIsOpen] = useState(true)
  const [emailInput, setEmailInput] = useState('')

  const [t] = useTranslation()

  const saveEmail = useCallback(async () => {
    await api
      .post('/v2/settings/set_email', {
        email: emailInput,
      })
      .then(res => {
        if (res.success) {
          refreshUserData()
          setIsOpen(false)
        } else {
          ccAlert(t('Something went wrong'))
        }
      })
      .catch(err => {
        ccAlert(err)
      })
  }, [t, emailInput])

  return (
    <RoundedBgModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <View style={styles.content}>
        <Text style={[styles.title, styles.bold]}>{t('Protect your account')}</Text>
        <Text style={styles.subtitle}>
          {t('Add an email address to your account to ensure access if you lose access to your socials')}
        </Text>
        <TextInput
          value={emailInput}
          onChangeText={value => {
            setEmailInput(value)
          }}
          style={[styles.magicLinkInput, styles.normal]}
          placeholderTextColor={renderColors.blackAlpha30}
          placeholder="hello@curiouscat.live"
        />
        <CCButton
          style={{
            width: '100%',
          }}
          onPress={() => {
            if (emailInput.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g)) {
              saveEmail()
            } else {
              ccAlert(t('Invalid email address'))
            }
          }}
          title={t('Save email address')}
        />
      </View>
    </RoundedBgModal>
  )
}

const webFontFallbacks = Platform.OS === 'web' ? ', Roboto, Helvetica, Arial, sans-serif' : ''

const styles = ThemedStyleSheet.create({
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
  },
  bold:
    Platform.OS === 'ios'
      ? {
          fontWeight: 'bold',
        }
      : {
          fontFamily: `Roboto-Bold${webFontFallbacks}`,
        },
  normal:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-Regular${webFontFallbacks}`,
        },
  title: {
    fontSize: 22,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'center',
    marginVertical: 15,
    maxWidth: 500,
  },
  magicLinkInput: {
    width: '100%',
    padding: 10,
    backgroundColor: stylesheetColors.white,
    color: stylesheetColors.black,
    fontSize: 16,
    borderRadius: 5,
    textAlign: 'left',
    marginTop: 'auto',
    borderColor: stylesheetColors.grey,
    borderWidth: 2,
    marginVertical: 15,
  },
})

export default SetEmailModal
