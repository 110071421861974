import { Platform } from 'react-native'

import base64Encode from './base64Encode'
import getMainDomain from './getMainDomain'
import { t, changeLang, getCurrentLang } from './i18n'
import { logout } from './logout'
import { onboardingAB } from './onboardingAB'
import { userData, saveUserData, saveUserSettings } from './user'
import { getUserAgent } from '../misc/appVersion'
import { menuNavItemsSetCounts } from '../routers/MenuTabBar'

function getApiBaseUrl() {
  if (__DEV__) {
    if (process.env.DEV_API_URL) {
      // If DEV_API_URL is set, use it
      // To set it, just prepend the env var to the command like `DEV_API_URL=http://whatever/ yarn start`
      return process.env.DEV_API_URL
    }

    // If DEV_API_URL env var is not set, fallback to production API
    return `https://${getMainDomain()}/api`
  } else {
    if (Platform.OS === 'web' && window.location.hostname) {
      // On web, use the current domain to avoid CORS
      return `https://${window.location.hostname}/api`
    }

    return `https://${getMainDomain()}/api`
  }
}

export default { get, post }

function get(url, data = {}) {
  data._ob = onboardingAB

  let qs = ''
  if (Object.keys(data).length) {
    qs =
      '?' +
      Object.keys(data)
        .map(key => `${key}=${data[key]}`)
        .join('&')
  }
  return apiFetch(url + qs, {
    method: 'GET',
  })
}

function post(url, data = {}) {
  if (!(data instanceof FormData)) {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        for (let i = 0; i < data[key].length; i++) {
          formData.append(`${key}[]`, data[key][i])
        }
      } else {
        formData.append(key, data[key])
      }
    })
    data = formData
  }
  data.append('_ob', onboardingAB)

  return apiFetch(url, {
    method: 'POST',
    body: data,
  })
}

class ApiError extends Error {
  constructor({ message, error_code }) {
    super(message)
    this.name = 'ApiError'
    this.error_code = error_code
  }
}

async function apiFetch(url, options = {}) {
  if (!options.headers) options.headers = {}
  if (userData) options.headers.Authorization = 'Basic ' + base64Encode('0:' + userData.session_id)
  if (Platform.OS !== 'web') options.headers['User-Agent'] = getUserAgent()
  options.headers['Accept-Language'] = userData ? userData.lang : getCurrentLang()

  return await fetchWithTimeout(getApiBaseUrl() + url, options)
    .then(async res => {
      let result
      try {
        result = await res.json()
      } catch (_) {
        throw new Error(t('Invalid API response'))
      }

      if (userData && result.error_code === 'not_logged_in') {
        await logout()
      }
      if (res.status !== 200 || result.error) {
        let resultMessage = result.error || res.statusText || res.body
        if (result.error_code === 'ratelimited')
          resultMessage = t(
            "You've been temporarily blocked from performing this action. Try again later, if you think this is a mistake let us know"
          )
        throw new ApiError({
          message: resultMessage,
          error_code: result.error_code,
        })
      }

      if (userData) {
        menuNavItemsSetCounts({
          inbox: result.inbox_count,
          notifications: result.notifications_count,
        })
      }

      return result
    })
    .catch(err => {
      if (err.message === 'Network request failed' || err.message === 'Failed to fetch') {
        throw new Error(t('CuriousCat seems to be down 😪. Please try again in a minute!'))
      }
      throw err
    })
}

function fetchWithTimeout(url, options, timeout = 35000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error(t('Request has timed out'))), timeout)),
  ])
}

export async function refreshUserData() {
  const res = await get('/v2/me')

  const timezoneOffset = -new Date().getTimezoneOffset()
  if (res.timezone_offset !== timezoneOffset) {
    post('/v2/settings/timezone_offset', {
      timezone_offset: timezoneOffset,
    }).catch(() => {
      /* Ignore error */
    })
  }

  await Promise.all([
    saveUserData({
      ...res.user,
      session_id: userData.session_id,
      country: res.country,
      lang: res.lang,
      ga_uid: res.ga_uid,
      timezone_offset: res.timezone_offset,
      email: res.email,
      can_receive_gifts: res.user.can_receive_gifts,
      created_at: res.user.created_at,
      id: res.user.id,
    }),
    saveUserSettings(res.settings),
  ])
  changeLang(res.lang, { userInteraction: false })
}
