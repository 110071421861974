import React from 'react'

import LikeAppStore from './LikeAppStore'
//import GentleModal from './GentleModal' // might be used in the future

export default function CTADownloadNativeApp() {
  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
  if (!isMobile) return null

  return <LikeAppStore />
}
