export const SPLASH_SCREEN_ZINDEX = 50000

export const MODAL_ALERT_ZINDEX = 10400
export const MODAL_OPTIONS_ZINDEX = 10300
export const MODAL_ROUNDEDBG_ZINDEX = 10200
export const MODAL_SCREEN_ZINDEX = 10100
export const MODAL_CCMODAL_CONTAINER_ZINDEX = 10001
export const MODAL_CCMODAL_BG_ZINDEX = 10000

export const TUTORIAL_TIP_PANEL_ZINDEX = 3001
export const TUTORIAL_BG_COVER_ZINDEX = 3000

export const PROFILE_SET_EMOJI_CONTAINER_ZINDEX = 111
export const PROFILE_SET_EMOJI_BG_ZINDEX = 110
export const PROFILE_STICKY_HEADER_ZINDEX = 100
export const PROFILE_HEADER_ZINDEX = 90 // Needed for SET_EMOJI
