// Used in feed and profiles
export default function preparePostListItemForState(item) {
  if (item.type === 'status') {
    return {}
  }

  return {
    type: item.type,
    postID: item.post.id,
    sharedBy: item.shared_by || undefined,
    timestamp:
      item.type === 'shared_post' ? item.shared_timestamp : item.type === 'post' ? item.post.timestamp : undefined,
    key: `post-${item.post.id}-${item.shared_by ? item.shared_by.id : ''}`,
  }
}
