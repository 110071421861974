import React, { FC } from 'react'
import { Text, TextStyle, TouchableOpacity, ViewStyle } from 'react-native'

import { stylesheetColors, ThemedStyleSheet } from '../../colors'

type CCButtonProps = {
  type?: 'normal' | 'bold' | 'orange' | 'red' | 'transparent'
  title?: string
  disabled?: boolean
  onPress?: () => void
  style?: ViewStyle | (ViewStyle | undefined)[]
  textStyle?: TextStyle
  accessibilityLabel?: string
  children?: React.ReactNode
}

const CCButton: FC<CCButtonProps> = ({
  type = 'normal',
  title,
  onPress,
  disabled,
  style,
  textStyle,
  children,
  accessibilityLabel,
  ...props
}) => {
  const buttonTypeStyle =
    type === 'normal'
      ? styles.buttonNormal
      : type === 'bold'
      ? styles.buttonBold
      : type === 'red'
      ? styles.buttonRed
      : type === 'transparent'
      ? styles.buttonTransparent
      : styles.buttonOrange
  const textTypeStyle =
    type === 'normal'
      ? styles.textNormal
      : type === 'bold'
      ? styles.textBold
      : type === 'red'
      ? styles.textRed
      : type === 'transparent'
      ? styles.textTransparent
      : styles.textOrange

  return (
    <TouchableOpacity
      {...props}
      style={[styles.button, buttonTypeStyle, disabled && styles.buttonDisabled, style]}
      accessibilityLabel={accessibilityLabel}
      onPress={disabled ? undefined : onPress}>
      {children ? (
        children
      ) : (
        <Text style={[styles.text, textTypeStyle, disabled && styles.textDisabled, textStyle]}>
          {title && title.length ? title : ''}
        </Text>
      )}
    </TouchableOpacity>
  )
}

const styles = ThemedStyleSheet.create({
  button: {
    borderRadius: 4,
    paddingHorizontal: 69,
    paddingVertical: 5,
  },
  buttonNormal: {
    backgroundColor: stylesheetColors.lightGrey,
  },
  buttonBold: {
    backgroundColor: stylesheetColors.lightGrey,
    paddingVertical: 11,
    borderRadius: 15,
  },
  buttonOrange: {
    backgroundColor: stylesheetColors.orange,
  },
  buttonRed: {
    backgroundColor: stylesheetColors.red,
  },
  buttonTransparent: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: stylesheetColors.black,
    paddingHorizontal: 40,
    minWidth: 130,
  },
  buttonDisabled: {
    opacity: 0.6,
  },
  text: {
    fontWeight: '500',
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 25,
  },
  textNormal: {
    color: stylesheetColors.black,
  },
  textBold: {
    color: stylesheetColors.black,
    fontWeight: '700',
    fontSize: 16,
  },
  textOrange: {
    color: stylesheetColors.white,
  },
  textRed: {
    color: stylesheetColors.white,
  },
  textTransparent: {
    color: stylesheetColors.black,
  },
  textDisabled: {
    color: stylesheetColors.textLightGrey,
  },
})

export default CCButton
