import { locales } from 'expo-localization'

import translationStrings from './translationStrings'

const translationStringsNames = Object.keys(translationStrings)
const translationStringsNamesWithoutCountry = translationStringsNames.map(v => v.split('_')[0])

export default function detectUserDeviceLang() {
  let result = 'en_US'
  const localesWithoutCountry = locales.map(v => v.split('-')[0])

  for (const testLang of localesWithoutCountry) {
    const langListPos = translationStringsNamesWithoutCountry.indexOf(testLang)
    if (langListPos !== -1) {
      result = translationStringsNames[langListPos]
      break
    }
  }

  return result
}
