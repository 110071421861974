import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'

import Avatar from './Avatar'
import CCUsername from './CCUsername'
import CCButton from './designSystem/CCButton'
import Shimmer from './designSystem/Shimmer'
import { stylesheetColors, ThemedStyleSheet } from '../colors'
import api from '../lib/api'
import { formatNumber } from '../lib/formatNumber'
import { t } from '../lib/i18n'
import { goToProfile } from '../lib/navUtils'
import { userData } from '../lib/user'

UserInList.propTypes = {
  onRequestClose: PropTypes.func,
  user: PropTypes.object.isRequired,
}
export default function UserInList({ user, onRequestClose }) {
  const [followOverride, setFollowOverride] = useState()
  const isFollowing = followOverride ?? user.is_followed_by_me
  const isMe = user?.id === userData?.id

  const userPressed = () => {
    goToProfile(user.username)
    if (onRequestClose) onRequestClose()
  }
  const followButtonPressed = () => {
    // We need to use local state because users can be passed not from redux
    // This should be rewritten to use userID as a prop instead, but many uses of this component don't push the users to redux
    setFollowOverride(!isFollowing)
    api
      .post(isFollowing ? '/v2/profile/follow/delete' : '/v2/profile/follow/create', {
        username: user.username,
        via: 'userInList',
      })
      .catch(() => {
        /* Ignore error */
      })
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={userPressed}>
        <Avatar user={user} size={44} style={{ marginRight: 5 }} />
      </TouchableOpacity>
      <TouchableOpacity onPress={userPressed} style={styles.userTextContainer}>
        <CCUsername user={user} textSize={16} style={{ flexGrow: 1 }} />
        <Text style={styles.userAnswers}>{t('{{count}} Answers', { count: formatNumber(user.answers) })}</Text>
      </TouchableOpacity>
      {!isMe && (
        <CCButton
          type="transparent"
          onPress={followButtonPressed}
          title={isFollowing ? t('Unfollow') : user.is_following_me ? t('Follow Back') : t('Follow')}
          style={{ alignSelf: 'center' }}
        />
      )}
    </View>
  )
}

export function UserInListPlaceholder() {
  return (
    <View style={styles.container}>
      <Shimmer style={{ height: 44, width: 44, borderRadius: 22, marginRight: 5 }} />
      <View>
        <Shimmer style={{ height: 16 + 3, width: 100, marginBottom: 3 }} />
        <Shimmer style={{ height: 13 + 3, width: 60 }} />
      </View>
    </View>
  )
}

const styles = ThemedStyleSheet.create({
  container: {
    backgroundColor: stylesheetColors.white,
    flexDirection: 'row',
    padding: 10,
    borderBottomColor: stylesheetColors.lightGrey,
    borderBottomWidth: 1,
    alignItems: 'center',
  },
  userTextContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: 10,
  },
  userAnswers: {
    fontSize: 13,
    color: stylesheetColors.textLightGrey,
    marginTop: 3,
  },
})
