import PropTypes from 'prop-types'
import { useEffect } from 'react'

CCSplashScreen.propTypes = {
  isAppLoaded: PropTypes.bool.isRequired,
  isUserLoaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
export default function CCSplashScreen({ isAppLoaded, isUserLoaded, error }) {
  useEffect(() => {
    if (!isAppLoaded || !isUserLoaded) return

    hideWebSplashScreen()
  }, [isAppLoaded, isUserLoaded])

  if (error) {
    const loadingMessage = document.getElementById('loadingMessage')
    if (loadingMessage) {
      loadingMessage.style.opacity = '1'
      loadingMessage.style.marginTop = '10px'
      loadingMessage.innerText = error
    }
  }

  return null
}

function hideWebSplashScreen() {
  const hideMsAnimation = 200
  const webLoadingElm = document.getElementById('webLoading')
  const loadingLogoElm = document.getElementById('loadingLogo')
  if (!webLoadingElm) return
  const tsDiff = Date.now() - window.splashScreenInitialTimestamp
  const waitMs = Math.max(0, 600 - tsDiff) // Minimum of 600ms of splash screen, to not just flash an orange screen
  setTimeout(() => {
    // Container fade out
    webLoadingElm.style.transition = `opacity ${hideMsAnimation}ms ease-in-out`
    webLoadingElm.style.opacity = 0
    // Logo grow animation
    const shouldDoLogoGrowAnimation = tsDiff > 600
    if (shouldDoLogoGrowAnimation) {
      const currentScale =
        loadingLogoElm.getBoundingClientRect().height / parseInt(window.getComputedStyle(loadingLogoElm).height, 10)
      loadingLogoElm.style.transition = `transform ${hideMsAnimation}ms ease-in-out`
      loadingLogoElm.style.animation = 'none'
      loadingLogoElm.style.transform = `scale(${currentScale})`
      setTimeout(() => {
        loadingLogoElm.style.transform = 'scale(30)'
      }, 1)
    }
    // Unmount container
    setTimeout(() => {
      webLoadingElm.remove()
    }, hideMsAnimation)
  }, waitMs)
}
