import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { INBOX_FETCH_MORE_QUESTIONS, INBOX_RESET_QUESTIONS, INBOX_DELETE_QUESTION } from '../actions/types'

const initialState = {
  maxTimestamp: '',
  items: [],
}

function prepareQuestionForState(question) {
  return {
    id: question.id,
    key: question.id + '',
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case INBOX_RESET_QUESTIONS: {
      const newItems = action.items.map(prepareQuestionForState)

      const newState = Object.assign({}, state, {
        items: newItems,
        maxTimestamp: Math.min(...action.items.map(question => question.timestamp)),
      })
      return newState
    }
    case INBOX_FETCH_MORE_QUESTIONS: {
      let newItems = [...state.items, ...action.items.map(prepareQuestionForState)]
      newItems = newItems.filter((q, index, arr) => arr.findIndex(_q => _q.id === q.id) === index)

      const newState = Object.assign({}, state, {
        items: newItems,
        maxTimestamp: Math.min(...action.items.map(question => question.timestamp)),
      })
      return newState
    }
    case INBOX_DELETE_QUESTION: {
      const questionID = action.questionID

      const newItems = state.items.filter(q => q.id !== questionID)

      const newState = Object.assign({}, state, {
        items: newItems,
      })
      return newState
    }
    default:
      return state
  }
}

export function useGetInbox() {
  const inbox = useSelector(state => state.inbox.items)
  return useCallback(() => inbox, [inbox])
}
