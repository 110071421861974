import React, { useState, useEffect } from 'react'

export const modals = {}

export let staticRefreshContainer = () => {
  /* Ignore error */
}
let isRerendedingQueued = false

function ModalsContainer() {
  const [, _rerender] = useState()

  useEffect(() => {
    staticRefreshContainer = () => {
      if (isRerendedingQueued) return
      isRerendedingQueued = true
      requestAnimationFrame(() => {
        _rerender(Math.random())
        isRerendedingQueued = false
      })
    }
  }, [])

  return Object.values(modals)
}
export default React.memo(ModalsContainer)
