import { useEffect } from 'react'

export function goToProfile(username, { postID = 0, edit = false } = {}) {
  const screenOptions = postID
    ? { screen: 'ProfilePost', params: { username, postID } }
    : edit
    ? { screen: 'ProfileEdit', params: { username } }
    : { screen: 'Profile', params: { username } }

  const unsubscribe = ccNavigation.addListener('state', () => {
    unsubscribe()
    requestAnimationFrame(() => staticProfileSetParams(screenOptions.params))
  })
  ccNavigation.navigate(screenOptions.screen, screenOptions.params)
}

export let ccNavigation = null
export function setCCNavigation(newNav) {
  ccNavigation = newNav
}

let staticProfileSetParams = () => {
  /* Ignore error */
}
export function setStaticProfileSetParams(newFn) {
  staticProfileSetParams = newFn
}

export function useCloseOnNavigationChange({ isOpen, onRequestClose }) {
  useEffect(() => {
    if (!isOpen) return

    return ccNavigation.addListener('state', onRequestClose)
  }, [isOpen, onRequestClose])
}
