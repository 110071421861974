import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ADD_POSTS, POST_DELETE_POST, POST_UPDATE } from '../actions/types'

const initialState = {
  posts: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_POSTS: {
      const postsObj = {}

      action.posts.forEach(newPost => {
        // Add posts and in_response_to thread
        do {
          postsObj[newPost.id] = newPost
          newPost = newPost.in_response_to
        } while (typeof newPost === 'object')
      })

      if (Object.keys(postsObj).length === 0) return state // nothing to update

      const newState = Object.assign({}, state, {
        posts: Object.assign({}, state.posts, postsObj),
      })
      return newState
    }
    case POST_DELETE_POST: {
      const newPosts = Object.assign({}, state.posts)
      delete newPosts[action.post.id]
      const newState = Object.assign({}, state, {
        posts: newPosts,
      })
      return newState
    }
    case POST_UPDATE: {
      const changedPosts = {
        [action.postID]: Object.assign({}, state.posts[action.postID], action.changes),
      }
      const newState = Object.assign({}, state, {
        posts: Object.assign({}, state.posts, changedPosts),
      })
      return newState
    }
    default:
      return state
  }
}

export function usePosts() {
  const posts = useSelector(state => state.posts.posts)
  return posts
}

export function getPost(state, postID) {
  return state.posts.posts[postID]
}

export function usePost(postID) {
  const post = useSelector(state => state.posts.posts[postID])
  return post
}

export function useGetPost() {
  const posts = useSelector(state => state.posts.posts)
  return postID => posts[postID]
}

export function useGetPostThread() {
  const posts = useSelector(state => state.posts.posts)

  return useCallback(
    postID => {
      const thread = []
      while (1) {
        const post = posts[postID]
        if (!post) {
          thread.unshift(true)
          break
        }
        thread.unshift(post.id)
        if (!post.in_response_to) break
        postID = post.in_response_to.id
      }

      return thread
    },
    [posts]
  )
}

export const useAddPosts = () => {
  const dispatch = useDispatch()
  return useCallback(
    posts => {
      dispatch({
        type: ADD_POSTS,
        posts,
      })
    },
    [dispatch]
  )
}
