import PropTypes from 'prop-types'
import React from 'react'
import { KeyboardAvoidingView, Platform } from 'react-native'

import { ThemedStyleSheet } from '../../colors'

CCKeyboardAvoidingView.propTypes = {
  ...KeyboardAvoidingView.propTypes,
  keyboardVerticalOffset: PropTypes.number,
}

export default function CCKeyboardAvoidingView({ style = undefined, keyboardVerticalOffset = 0, ...props }) {
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
      style={[styles.default, style]}
      {...props}
    />
  )
}

const styles = ThemedStyleSheet.create({
  default: {
    flex: 1,
  },
})
