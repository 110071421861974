import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect, useCallback } from 'react'
import { View, Animated } from 'react-native'

import CCModal from './CCModal'
import ModalPortal from './ModalPortal'
import { stylesheetColors, ThemedStyleSheet } from '../../colors'
import layout, { useOnDimensionsChange } from '../../lib/layout'
import { useCloseOnNavigationChange } from '../../lib/navUtils'
import { MODAL_ROUNDEDBG_ZINDEX } from '../../misc/zIndexes'
import CCKeyboardAvoidingView from '../designSystem/CCKeyboardAvoidingView'

const ANIMATION_TIME = 150

RoundedBgModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element,
  onRequestClose: PropTypes.func,
}
function RoundedBgModal(props) {
  const keyRef = useRef(Math.random())
  const [isModalPortalOpen, setIsModalPortalOpen] = useState(props.isOpen)

  useEffect(() => {
    if (props.isOpen && !isModalPortalOpen) setIsModalPortalOpen(true)
  }, [props.isOpen, isModalPortalOpen])

  return (
    <ModalPortal isOpen={isModalPortalOpen}>
      <RoundedBg
        {...props}
        key={keyRef.current}
        closeModalPortal={useCallback(() => setIsModalPortalOpen(false), [])}
      />
    </ModalPortal>
  )
}
export default React.memo(RoundedBgModal)

RoundedBg.propTypes = {
  ...RoundedBgModal.propTypes,
  style: PropTypes.any,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  closeModalPortal: PropTypes.func.isRequired,
}
function RoundedBg({ isOpen, onRequestClose, children, style, closeModalPortal }) {
  useOnDimensionsChange()
  useCloseOnNavigationChange({ isOpen, onRequestClose })

  // Animate opening and closing
  const openPercentage = useRef(new Animated.Value(0))
  useEffect(() => {
    Animated.timing(openPercentage.current, {
      toValue: isOpen ? 1 : 0,
      duration: ANIMATION_TIME,
      useNativeDriver: true,
    }).start(isOpen ? undefined : closeModalPortal)
  }, [isOpen, closeModalPortal])

  const bgRef = useRef()

  return (
    <Animated.View style={[styles.root, { opacity: openPercentage.current }]}>
      <CCModal onRequestClose={onRequestClose}>
        <CCKeyboardAvoidingView>
          <View
            style={styles.modalContainer}
            onLayout={e => (bgRef.current = e.nativeEvent.target)}
            onStartShouldSetResponder={e => {
              if (e.nativeEvent.target === bgRef.current) onRequestClose()
              return false
            }}>
            <View
              style={[
                styles.contentContainer,
                {
                  marginTop: layout.statusBarHeight,
                  maxHeight: layout.drawableHeight - 40 - layout.statusBarHeight,
                  maxWidth: layout.drawableWidth - 40,
                },
                style,
              ]}>
              {children}
            </View>
          </View>
        </CCKeyboardAvoidingView>
      </CCModal>
    </Animated.View>
  )
}

const styles = ThemedStyleSheet.create({
  root: {
    ...ThemedStyleSheet.absoluteFillObject,
    zIndex: MODAL_ROUNDEDBG_ZINDEX,
  },
  modalContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  contentContainer: {
    overflow: 'hidden',
    alignSelf: 'center',
    backgroundColor: stylesheetColors.white,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 6,
  },
})
