import { combineReducers } from 'redux'

import feed from './feed'
import inbox from './inbox'
import posts from './posts'
import profiles from './profiles'

export default combineReducers({
  feed,
  posts,
  profiles,
  inbox,
})
