import PropTypes from 'prop-types'
import React from 'react'
import { View } from 'react-native'

import { renderColors } from '../../colors'

Shimmer.propTypes = {
  style: PropTypes.any,
}
export default function Shimmer({ style }) {
  return <View style={[{ borderRadius: 2, backgroundColor: renderColors.placeholderAnimation }, style]} />
}
