import { Text, Platform } from 'react-native'

import './Array.flat.polyfill' // Needed due to react-native-web using it to compute Stylesheets
import { stylesheetColors, ThemedStyleSheet } from '../colors'

export default function setCustomText() {
  try {
    // if (Platform.OS === 'web') Text.prototype.render = monkeyPatchRender(Text)
    // else Text.render = monkeyPatchRender(Text)
    Text.render = monkeyPatchRender(Text)
  } catch (e) {
    console.error(e)
  }
}

function monkeyPatchRender(Element) {
  const oldRenderFn = Element.render

  return function newRender(props, ref) {
    const oldProps = props
    const customStyleOverride = getTextCustomStyle(oldProps.style)
    const newProps = { ...oldProps, style: [styles.default, oldProps.style, customStyleOverride] }
    return oldRenderFn.apply(this, [newProps, ref])
  }
}

export function getTextCustomStyle(style = {}) {
  style = ThemedStyleSheet.flatten(style)
  if (style.fontFamily) return {}

  const isLight =
    style.fontWeight === 'lighter' ||
    style.fontWeight === '100' ||
    style.fontWeight === '200' ||
    style.fontWeight === '300'
  const isMedium = style.fontWeight === '500' || style.fontWeight === '600'
  const isBold =
    style.fontWeight === 'bold' ||
    style.fontWeight === '700' ||
    style.fontWeight === '800' ||
    style.fontWeight === '900'
  const weight = isBold ? 'bold' : isLight ? 'light' : isMedium ? 'medium' : 'normal'
  const isItalic = style.fontStyle === 'italic'
  const customStyleOverride = styles[`${weight}${isItalic ? '_italic' : ''}`]
  return customStyleOverride
}

const webFontFallbacks = Platform.OS === 'web' ? ', Roboto, Helvetica, Arial, sans-serif' : ''
const styles = ThemedStyleSheet.create({
  default: {
    fontSize: 16,
    textAlign: 'left',
    textAlignVertical: 'top',
    color: stylesheetColors.black,
    ...Platform.select({
      web: {
        userSelect: 'text',
      },
    }),
  },
  normal:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-Regular${webFontFallbacks}`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
  normal_italic:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-Italic${webFontFallbacks}`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
  medium:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-Medium${webFontFallbacks}`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
  medium_italic:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-MediumItalic${webFontFallbacks}`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
  light:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-Light${webFontFallbacks}`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
  light_italic:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-LightItalic${webFontFallbacks}`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
  bold:
    Platform.OS === 'ios'
      ? {}
      : { fontFamily: `Roboto-Bold${webFontFallbacks}`, fontWeight: 'normal', fontStyle: 'normal' },
  bold_italic:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-BoldItalic${webFontFallbacks}`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
})
export const ccDefaultTextStyle = styles.default
