import { useEffect } from 'react'

// For tutorial
const staticLists = []
export const scrollToBottomAllPaginationLists = () => {
  staticLists.forEach(list => {
    if (list.current && list.current.scrollToEnd) list.current.scrollToEnd({ animated: true })
  })
}
export function useAddToStaticList(ref) {
  useEffect(() => {
    staticLists.push(ref)
    return () => {
      staticLists.splice(staticLists.indexOf(ref), 1)
    }
  }, [ref])
}
