import { useEffect, useState } from 'react'
import { Dimensions, Platform, StatusBar, useWindowDimensions } from 'react-native'

import { ThemedStyleSheet } from '../colors'

const DESKTOP_WIDTH_BREAKPOINT = 890

export let isDesktop
const defaultExport = {
  screenWidth: 0,
  statusBarHeight: 0,
  drawableHeight: 0,
  drawableWidth: 0,
  bottomSafeSpace: 0,
  DESKTOP_WIDTH_BREAKPOINT,
}

const onDimensionsChange = () => {
  const windowDim = Dimensions.get('window')

  isDesktop = windowDim.width >= DESKTOP_WIDTH_BREAKPOINT

  defaultExport.screenWidth = windowDim.width
  defaultExport.statusBarHeight = getStatusBarHeight()
  defaultExport.drawableHeight = windowDim.height + defaultExport.statusBarHeight
  defaultExport.drawableWidth = Math.min(windowDim.width, DESKTOP_WIDTH_BREAKPOINT)
  defaultExport.bottomSafeSpace = hasIOSBottomHomeIndicator() ? 34 : 0
  defaultExport.DESKTOP_WIDTH_BREAKPOINT = DESKTOP_WIDTH_BREAKPOINT
}
onDimensionsChange()
Dimensions.addEventListener('change', onDimensionsChange)

export default defaultExport

function hasIOSBottomHomeIndicator() {
  if (
    // Test if iOS (native or web)
    !Platform.select({
      ios: !Platform.isPad && !Platform.isTVOS, // Only iPhones
      web: /iPhone/.test(window.navigator?.userAgent), // Only iPhones
      default: false,
    })
  )
    return false

  // Test web safe area
  if (Platform.OS === 'web' && webHasSafeAreaBottomInset()) return true

  // Test native (Using list of resolutions)
  // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions
  const dimensionsList = [
    [428, 926], // 14 Plus
    [430, 932], // 14 Pro Max
    [393, 852], // 14 Pro
    [390, 844], // 14
    [390, 844], // 13
    [375, 812], // 13 Mini
    [428, 926], // 13 Pro Max
    [390, 844], // 13 Pro
    [390, 844], // 12
    [375, 812], // 12 Mini
    [428, 926], // 12 Pro Max
    [390, 844], // 12 Pro
    [462, 1000], // 11 Pro Max, Xs Max
    [414, 896], // 11, Xr
    [375, 812], // 11 Pro, X, Xs
  ]

  const windowDim = Dimensions.get('window')
  return dimensionsList.some(dimensions => {
    return (
      (windowDim.width === dimensions[0] && windowDim.height === dimensions[1]) ||
      (windowDim.height === dimensions[0] && windowDim.width === dimensions[1])
    )
  })
}

function getStatusBarHeight() {
  return Platform.select({
    ios: hasIOSBottomHomeIndicator() ? 44 : 20,
    android: StatusBar.currentHeight,
    default: 0,
  })
}

function webHasSafeAreaBottomInset() {
  let proceed = false
  const div = document.createElement('div')
  if (window.CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'env(safe-area-inset-bottom)'
    proceed = true
  } else if (window.CSS.supports('padding-bottom: constant(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'constant(safe-area-inset-bottom)'
    proceed = true
  }
  if (proceed) {
    document.body.appendChild(div)
    const calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom, 10)
    document.body.removeChild(div)
    if (calculatedPadding > 0) {
      return true
    }
  }
  return false
}

const styles = ThemedStyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: DESKTOP_WIDTH_BREAKPOINT,
  },
})
export const fullWidthContainerStyle = styles.container

export function useOnDimensionsChange({ debounceMs = 100, callback = undefined } = {}) {
  const windowDimensions = useWindowDimensions()
  const [debouncedValue, setDebouncedValue] = useState(windowDimensions)

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(windowDimensions)
    }, debounceMs)

    return () => {
      clearTimeout(handler)
    }
  }, [debounceMs, callback, windowDimensions])

  useEffect(() => {
    if (callback !== undefined) {
      callback(debouncedValue)
    }
  }, [callback, debouncedValue])
}
