import { openLink } from '../../../lib/openLink'

const getAndroidPlaystoreLink = abtest =>
  `https://play.google.com/store/apps/details?id=me.curiouscat.app&referrer=utm_source%3Dccweb%26utm_content%3D${encodeURIComponent(
    abtest
  )}`
const getIOSAppstoreLink = abtest =>
  `https://apps.apple.com/us/app/curiouscat-anonymous-q-a/id1506954395?pt=118002435&ct=ccweb-${encodeURIComponent(
    abtest
  )}&mt=8`

export function openNativeAppLink(abtest) {
  const isAndroidOS = /android/i.test(navigator.userAgent || navigator.vendor)
  const url = isAndroidOS ? getAndroidPlaystoreLink(abtest) : getIOSAppstoreLink(abtest)
  openLink(url)
}
