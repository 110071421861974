import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, TouchableOpacity } from 'react-native'

import { ThemedStyleSheet, stylesheetColors, renderColors } from '../../colors'
import layout, { useOnDimensionsChange } from '../../lib/layout'
import { ccNavigation } from '../../lib/navUtils'
import SvgIcon from '../SvgIcon'
import CCButton from '../designSystem/CCButton'

export default function CTARegister() {
  const [isHidden, setIsHidden] = useState(false)

  const hideIfInLoginRoute = useCallback(() => {
    if (!ccNavigation) return
    const state = ccNavigation.getState()
    const routeName = state.routes?.[state?.index ?? 0]?.name
    setIsHidden(routeName === 'Login')
  }, [])

  useEffect(() => {
    hideIfInLoginRoute()
    return ccNavigation.addListener('state', hideIfInLoginRoute)
  }, [hideIfInLoginRoute])

  useOnDimensionsChange()
  const [t] = useTranslation()
  const containerWidth = Math.min(layout.screenWidth - 30, layout.DESKTOP_WIDTH_BREAKPOINT)

  if (isHidden) return null

  return (
    <View style={[styles.container, { width: containerWidth }]}>
      <View style={styles.logoContainer}>
        <SvgIcon svg={require('./register_img/logo.svg')} size={32} color={renderColors.orange} accessibilityLabel="" />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.textTitle}>CuriousCat</Text>
        <Text style={styles.textSubtitle}>{t('Anonymous questions, and answers with friends!')}</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <CCButton
          title={t('Create Account')}
          type="orange"
          style={styles.createAccountButton}
          onPress={() => {
            ccNavigation.navigate('Login')
            setIsHidden(true)
          }}
        />
        <TouchableOpacity
          onPress={() => {
            setIsHidden(true)
          }}>
          <Text style={styles.textDismiss}>{t('Dismiss')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = ThemedStyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 35 + layout.bottomSafeSpace,
    alignSelf: 'center',
    backgroundColor: stylesheetColors.white,
    padding: 15,
    flexDirection: 'row',
    borderRadius: 2,
    // Shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  logoContainer: {
    padding: 9,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: stylesheetColors.grey,
    backgroundColor: stylesheetColors.lightGrey,
    marginRight: 10,
    alignSelf: 'center',
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  textTitle: {
    fontWeight: '500',
    fontSize: 16,
    marginBottom: 2,
  },
  textSubtitle: {
    color: stylesheetColors.textLightGrey,
    fontSize: 12,
  },
  buttonsContainer: {
    alignItems: 'center',
    justifyContent: 'space-around',
    marginLeft: 20,
  },
  createAccountButton: {
    paddingHorizontal: 10,
    borderRadius: 15,
  },
  textDismiss: {
    paddingTop: 10,
    fontSize: 12,
    color: stylesheetColors.textLightGrey,
    borderBottomWidth: 1,
    borderBottomColor: stylesheetColors.textLightGrey,
  },
})
