import React, { useState, useEffect } from 'react'
import { View } from 'react-native'

import TipPanel from './TipPanel'
import { tutorialScreens, setOpenFindFriendsModal } from './tutorialScreens'
import { ThemedStyleSheet, stylesheetColors } from '../../colors'
import { TUTORIAL_BG_COVER_ZINDEX } from '../../misc/zIndexes'
import FindFriendsModal from '../actionModals/FindFriendsModal'

export let staticOpenTutorial = () => {
  /* Ignore error */
}

export default function Tutorial() {
  const [activeScreenIndex, setActiveScreenIndex] = useState(0)
  const activeScreen = tutorialScreens[activeScreenIndex]
  const [isFindFriendsOpen, setIsFindFriendsOpen] = useState(false)
  const [isTutorialOpen, setIsTutorialOpen] = useState(false)
  useEffect(() => {
    staticOpenTutorial = () => setIsTutorialOpen(true)
  }, [])

  setOpenFindFriendsModal(() => setIsFindFriendsOpen(true))

  useEffect(() => {
    if (isTutorialOpen && activeScreen?.beforeMount) {
      activeScreen.beforeMount()
    }
  }, [activeScreen, isTutorialOpen])

  if (!activeScreen || !isTutorialOpen) return null

  const onNextPressed = () => {
    setActiveScreenIndex(activeScreenIndex + 1)
  }

  return (
    <>
      <View
        style={styles.bgCover}
        onStartShouldSetResponder={() => {
          onNextPressed()
          return false
        }}
      />
      <TipPanel
        title={activeScreen.title}
        text={activeScreen.text}
        progressString={`${activeScreenIndex + 1}/${tutorialScreens.length}`}
        containerPos={activeScreen.containerPos}
        arrowXPos={activeScreen.arrowXPos}
        invertedArrow={activeScreen.invertedArrow}
        onSkipPressed={() => {
          setActiveScreenIndex(tutorialScreens.length)
        }}
        onNextPressed={onNextPressed}
      />
      <FindFriendsModal isOpen={isFindFriendsOpen} onRequestClose={() => setIsFindFriendsOpen(false)} />
    </>
  )
}

const styles = ThemedStyleSheet.create({
  bgCover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: stylesheetColors.modalSemiopaqueBackground,
    opacity: 0.5,
    zIndex: TUTORIAL_BG_COVER_ZINDEX,
  },
})
