import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, Platform, Text, TouchableOpacity, View } from 'react-native'

import { stylesheetColors, ThemedStyleSheet } from '../../colors'
import CCButton from '../designSystem/CCButton'
import RoundedBgModal from '../modals/RoundedBgModal'

const AnswerSurveyModal = () => {
  const [isOpen, setIsOpen] = useState(true)

  const [t] = useTranslation()

  return (
    <RoundedBgModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
      <View style={styles.content}>
        <Text style={[styles.title, styles.bold]}>{t('Help us improve!')}</Text>
        <Text style={styles.subtitle}>
          {t("We'd really appreciate if you could take a couple minutes to help us improve CuriousCat ❤️")}
        </Text>
        <CCButton
          style={{
            width: '100%',
          }}
          onPress={() => {
            Linking.openURL('https://forms.gle/6u5JgqnCXzbyUuvS7')
          }}
          title={t('Go to survey')}
        />
        <TouchableOpacity style={styles.cancelButton} onPress={() => setIsOpen(false)}>
          <Text style={[styles.normal, styles.cancelText]}>{t('No, thank you')}</Text>
        </TouchableOpacity>
      </View>
    </RoundedBgModal>
  )
}

const webFontFallbacks = Platform.OS === 'web' ? ', Roboto, Helvetica, Arial, sans-serif' : ''

const styles = ThemedStyleSheet.create({
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
  },
  bold:
    Platform.OS === 'ios'
      ? {
          fontWeight: 'bold',
        }
      : {
          fontFamily: `Roboto-Bold${webFontFallbacks}`,
        },
  normal:
    Platform.OS === 'ios'
      ? {}
      : {
          fontFamily: `Roboto-Regular${webFontFallbacks}`,
        },
  title: {
    fontSize: 22,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'center',
    marginVertical: 15,
    maxWidth: 500,
  },
  magicLinkInput: {
    width: '100%',
    padding: 10,
    backgroundColor: stylesheetColors.white,
    color: stylesheetColors.black,
    fontSize: 16,
    borderRadius: 5,
    textAlign: 'left',
    marginTop: 'auto',
    borderColor: stylesheetColors.grey,
    borderWidth: 2,
    marginVertical: 15,
  },
  cancelButton: {
    alignSelf: 'center',
  },
  cancelText: {
    textDecorationLine: 'underline',
    fontSize: 12,
    marginTop: 15,
    textTransform: 'uppercase',
    opacity: 0.7,
  },
})

export default AnswerSurveyModal
