import React, { FC, useEffect, useMemo, useState } from 'react'
import { View, Platform } from 'react-native'

import { stylesheetColors, ThemedStyleSheet } from './colors'
import CTADownloadNativeApp from './components/CTA/CTADownloadNativeApp'
import CTARegister from './components/CTA/CTARegister'
import ErrorBoundary from './components/ErrorBoundary'
import WebPushConfirmLayer from './components/Notification/WebPushConfirmLayer'
import AnswerSurveyModal from './components/actionModals/AnswerSurveyModal'
import SetEmailModal from './components/actionModals/SetEmailModal'
import ModalsContainer from './components/modals/ModalsContainer'
import Tutorial from './components/tutorial/Tutorial'
import asyncStorage from './lib/asyncStorage'
import { useShouldRenderAppDueToAppReload } from './lib/reloadApp'
import { userData } from './lib/user'

const PlaceHolder: FC = () => {
  return <></>
}

const Routers: {
  LoggedInRouter: FC
  LoggedOutRouter: FC
} = {
  LoggedInRouter: PlaceHolder,
  LoggedOutRouter: PlaceHolder,
}
export function setRouters(loggedInRouter: FC, loggedOutRouter: FC) {
  Routers.LoggedInRouter = loggedInRouter
  Routers.LoggedOutRouter = loggedOutRouter
}

export default function Main() {
  const shouldRender = useShouldRenderAppDueToAppReload()

  const [hasSeenEmailModal, setHasSeenEmailModal] = useState<boolean | null>(null)
  const [hasSeenSurveyModal, setHasSeenSurveyModal] = useState<boolean | null>(null)

  const couldShowSurveyModal = useMemo(() => {
    /* if (userData && userData.created_at && userData.id) {
      const userId = userData.id.toString()
      const lastUserIdNumber = userId[userId.length - 1]
      return (
        (lastUserIdNumber === '0' || lastUserIdNumber === '1') &&
        Date.now() / 1000 - userData.created_at >= 60 * 60 * 24 * 7
      )
    } */

    return false
  }, [])

  useEffect(() => {
    const emailModal = async () => {
      try {
        const value = await asyncStorage.getItem('emailModal')
        if (value !== undefined) {
          setHasSeenEmailModal(true)
        } else {
          await asyncStorage.setItem('emailModal', 'true')
        }
      } catch (err) {
        console.error(err)
      }
    }
    const surveyModal = async () => {
      try {
        const value = await asyncStorage.getItem('surveyModal')
        if (value !== undefined) {
          setHasSeenSurveyModal(true)
        } else {
          if (couldShowSurveyModal && !hasSeenSurveyModal) {
            await asyncStorage.setItem('surveyModal', 'true')
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
    emailModal()
    surveyModal()
  }, [couldShowSurveyModal, hasSeenSurveyModal])

  if (!shouldRender) {
    return null
  }

  return (
    <ErrorBoundary>
      <View style={styles.bodyContainer}>
        <View style={styles.appContainer} testID="appContainer">
          {userData ? <Routers.LoggedInRouter /> : <Routers.LoggedOutRouter />}
        </View>
        <ModalsContainer />
        <WebPushConfirmLayer />
        <CTADownloadNativeApp />
        {userData &&
          !userData.email &&
          userData.created_at &&
          Date.now() / 1000 - userData.created_at >= 60 * 60 * 24 &&
          !hasSeenEmailModal && <SetEmailModal />}
        {!userData && <CTARegister />}
        {couldShowSurveyModal && !hasSeenSurveyModal && <AnswerSurveyModal />}
        <Tutorial />
      </View>
    </ErrorBoundary>
  )
}

const styles = ThemedStyleSheet.create({
  bodyContainer: {
    flexGrow: 1,
    backgroundColor: stylesheetColors.white,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  appContainer: {
    flex: 1,
    ...Platform.select({
      // Disable overscroll on safari
      web: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      },
    }),
  },
})
