import { NavigationContainer } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import * as React from 'react'
import { useEffect, useState, useRef, useCallback, type MutableRefObject } from 'react'
import { Platform } from 'react-native'
// @ts-expect-error This library is not typed
import { patchFlatListProps } from 'react-native-web-refresh-control'
import { Provider } from 'react-redux'

import Main from './src/Main'
import { navigationTheme } from './src/colors'
import CCSplashScreen from './src/components/designSystem/CCSplashScreen'
import initialize from './src/init'
import { ccAlert } from './src/lib/alerts'
import { startLoginProcess } from './src/lib/login'
import { pageview } from './src/misc/googleAnalytics'
import preloadResources, { preloadUser } from './src/misc/preloadResources'
import setCustomText from './src/misc/setCustomText'
import useCCLinking, { ccLinkingConfig } from './src/misc/useCCLinking'
import store from './src/store'

initialize()

const linking = {
  prefixes: [
    'https://localhost:19006/',
    'https://dev.curiouscat.me:19006/',
    'https://dev.curiouscat.live:19006/',
    'https://curiouscat.me/',
    'https://curiouscat.live/',
    'curiouscat://',
  ],
  config: ccLinkingConfig,
}

export default function App() {
  const routeKeyRef = useRef<string>()
  const [areResourcesLoaded, setAreResourcesLoaded] = useState(false)
  const [isUserLoaded, setIsUserLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const { isNavReady, initialNavState } = useCCLinking()
  const [params, setParams] = useState({})
  const url = Linking.useURL()

  useEffect(() => {
    const updateParams = () => {
      if (!url) {
        return null
      }
      const initialUrl = Linking.parse(url)
      // Update the paramsRef with the new parameters
      setParams(initialUrl.queryParams || {})
    }

    // Call updateParams() once to initialize the paramsRef
    updateParams()
  }, [url])

  const redeemMagic = useCallback((magic_link: string) => {
    startLoginProcess('email::redeem', () => null, { magic_link }).catch(err => {
      ccAlert(err.message)
    })
  }, [])

  useEffect(() => {
    const checkMagicLink = async () => {
      try {
        if (params && 'magic_link' in params) {
          const magic_link = params.magic_link
          if (typeof magic_link === 'string') {
            if (Platform.OS === 'web') {
              const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
              const isIOS =
                /iPad|iPhone|iPod/.test(platform) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1)
              if (isIOS && !('deep_link' in params)) {
                location.assign(`curiouscat://?magic_link=${magic_link}`)
                setTimeout(() => {
                  location.assign(`/?magic_link=${magic_link}&deep_link=false`)
                }, 1000)
              } else {
                redeemMagic(magic_link)
              }
            } else {
              redeemMagic(magic_link)
            }
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          ccAlert(err.message)
        }
      }
    }
    checkMagicLink()
  }, [params, redeemMagic])

  useEffect(() => {
    // Load resources
    preloadResources()
      .then(() => {
        setAreResourcesLoaded(true)
      })
      .catch(error => {
        setErrorMessage(error.message)
        throw error
      })

    // Load user
    preloadUser()
      .then(() => {
        setIsUserLoaded(true)
      })
      .catch(error => {
        setErrorMessage(error.message)
        throw error
      })

    // Patch defaults
    setCustomText()
    patchFlatListProps()
  }, [])

  // Google Analytics
  const { onStateChange } = useGoogleAnalytics({ initialNavState, routeKeyRef, isUserLoaded })

  const isAppLoaded = isNavReady && areResourcesLoaded

  return (
    <>
      {isAppLoaded && isUserLoaded && (
        <NavigationContainer
          initialState={initialNavState}
          linking={linking}
          theme={navigationTheme}
          onStateChange={onStateChange}>
          <Provider store={store}>
            <Main />
          </Provider>
        </NavigationContainer>
      )}

      <CCSplashScreen error={errorMessage} isAppLoaded={isAppLoaded} isUserLoaded={isUserLoaded} />
    </>
  )
}

// eslint-disable-next-line
type InitialNavState = any

function useGoogleAnalytics({
  initialNavState,
  routeKeyRef,
  isUserLoaded,
}: {
  initialNavState: InitialNavState
  routeKeyRef: MutableRefObject<undefined | string>
  isUserLoaded: boolean
}) {
  // Send initial google analytics request
  useEffect(() => {
    if (!isUserLoaded) return
    setTimeout(pageview, 100)
  }, [isUserLoaded])

  // Send pageviews on route changes
  useEffect(() => {
    if (!initialNavState) {
      return () => {
        // Do nothing
      }
    }

    setTimeout(() => {
      const firstRoute = initialNavState?.routes[0]
      routeKeyRef.current = firstRoute.state ? getActiveRouteKey(firstRoute.state) : firstRoute.name
    }, 100)
  }, [initialNavState, routeKeyRef])

  const onStateChange = useCallback(
    (state: InitialNavState) => {
      const previousRouteKey = routeKeyRef.current
      const currentRouteKey = state ? getActiveRouteKey(state) : 'Home'

      if (previousRouteKey !== currentRouteKey) pageview()

      // Save the current route name for later comparision
      routeKeyRef.current = currentRouteKey
    },
    [routeKeyRef]
  )
  return { onStateChange }
}

function getActiveRouteKey(state: InitialNavState): string {
  const route = state?.routes[state?.index ?? 0]

  if (!route) {
    throw new Error('Unable to find route from state')
  }

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteKey(route.state)
  }

  const paramsStr = JSON.stringify(route.params)
  return route.name + '?' + paramsStr
}
