// Trap tab focus on web
export default function trapFocusUtil(element, { noChangeFocus = false }) {
  const isAndroid = navigator.userAgent.match(/(android)|(webOS)/i)
  if (isAndroid) return // disable on android due to bug when selecting inputs. We should explore and fix that

  const KEYCODE_TAB = 9

  // Determine focusable elements, and react to DOM changes
  let firstFocusableEl
  let lastFocusableEl
  updateFocusableElms()
  observeDOM(element, updateFocusableElms)

  if (!firstFocusableEl) return // TODO: Figure out what to do if there's nothing to focus

  // Auto focus first element if appropiate
  if (!noChangeFocus) firstFocusableEl.focus()

  // Add tab events listener
  element.addEventListener('keydown', onKeyDown)

  function onKeyDown(e) {
    const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

    if (!isTabPressed) {
      return
    }

    if (e.shiftKey) {
      /* shift + tab */ if (window.document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        e.preventDefault()
      }
    } /* tab */ else {
      if (window.document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        e.preventDefault()
      }
    }
  }

  function updateFocusableElms() {
    const focusableEls = element.querySelectorAll('[tabindex="0"]')
    firstFocusableEl = focusableEls[0]
    lastFocusableEl = focusableEls[focusableEls.length - 1]
  }
}

const observeDOM = (function () {
  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver

  return function (obj, callback) {
    if (!obj || !obj.nodeType === 1) return // validation

    if (MutationObserver) {
      // define a new observer
      const obs = new MutationObserver(function (mutations) {
        callback(mutations)
      })
      // have the observer observe foo for changes in children
      obs.observe(obj, { childList: true, subtree: true })
    } else if (window.addEventListener) {
      obj.addEventListener('DOMNodeInserted', callback, false)
      obj.addEventListener('DOMNodeRemoved', callback, false)
    }
  }
})()
