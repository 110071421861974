// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import AsyncStorage from '@react-native-async-storage/async-storage'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { StyleSheet, Platform, StatusBar, Appearance } from 'react-native'

import { reloadApp } from './lib/reloadApp'

export let activeTheme: 'dark' | 'light' = Appearance.getColorScheme() === 'dark' ? 'dark' : 'light'
const themes: Record<string, Record<string, unknown>> = {
  light: {
    white: '#FFFFFF',
    orange: '#DE7C00',
    black: '#1C1C1C',
    grey: '#DDDDDD',
    darkGrey: '#404040',
    lightGrey: '#F1F1F1',
    placeholderAnimation: '#EAEAEA',
    textLightGrey: '#BEBEBE',
    red: '#DE0000',
    lightGreyAlpha0: 'rgba(241, 241, 241, 0)',
    blackAlpha30: 'rgba(0, 0, 0, 0.3)',
    modalSemiopaqueBackground: 'rgba(0, 0, 0, 0.5)',
    whiteOverSemiopaqueBackground: '#FFFFFF',
    partyBlue: '#00CCCC',
    partyRed: '#E81A47',
    partyPurple: '#6633CC',
    partyTextGrey: '#BEBEBE',
    partyMessagesContainer: '#F1F1F1',
    partyGreenOnline: '#3EA75A',
    partyOrangeAnonymous: '#EA8B12',
    partyRedCrush: '#C85250',
    partyGreyButtons: '#F0F0F0',
  },
  dark: {
    white: '#1d1e22',
    orange: '#DE7C00',
    black: '#e3e3e3',
    grey: '#454545',
    darkGrey: '#bfbfbf',
    lightGrey: '#25252b',
    placeholderAnimation: '#2b2b31',
    textLightGrey: '#4e4e4e',
    red: '#ee4c4c',
    lightGreyAlpha0: 'rgba(37, 37, 43, 0)',
    blackAlpha30: 'rgba(120, 120, 120, 0.3)',
    modalSemiopaqueBackground: 'rgba(10, 10, 10, 0.6)',
    whiteOverSemiopaqueBackground: '#FFFFFF',
    partyBlue: '#00CCCC',
    partyRed: '#E81A47',
    partyPurple: '#6633CC',
    partyTextGrey: '#BEBEBE',
    partyMessagesContainer: '#F1F1F1',
    partyGreenOnline: '#3EA75A',
    partyOrangeAnonymous: '#EA8B12',
    partyRedCrush: '#C85250',
    partyGreyButtons: '#F0F0F0',
  },
}

function replaceStyleVariables<T extends object>(style: T): T {
  const theme = themes[activeTheme]
  const newStyle = {} as T

  Object.keys(style).forEach(propKey => {
    // @ts-expect-error TS is weird
    let propVal = style[propKey]

    if (typeof propVal === 'string' && propVal.startsWith('$')) {
      const key = propVal.slice(1)
      propVal = theme[key]
    }

    // @ts-expect-error TS is weird
    newStyle[propKey] = propVal
  })

  return newStyle
}

function setStatusBarStyle() {
  const barStyle = Platform.select({
    ios: activeTheme === 'dark' ? 'light-content' : 'dark-content',
    default: 'default',
  } as const)
  StatusBar.setBarStyle(barStyle)
}

export const stylesheetColors = {
  white: '$white',
  orange: '$orange',
  black: '$black',
  grey: '$grey',
  darkGrey: '$darkGrey',
  lightGrey: '$lightGrey',
  placeholderAnimation: '$placeholderAnimation',
  textLightGrey: '$textLightGrey',
  red: '$red',
  lightGreyAlpha0: '$lightGreyAlpha0',
  blackAlpha30: '$blackAlpha30',
  modalSemiopaqueBackground: '$modalSemiopaqueBackground',
  whiteOverSemiopaqueBackground: '$whiteOverSemiopaqueBackground',
  partyBlue: '$partyBlue',
  partyRed: '$partyRed',
  partyPurple: '$partyPurple',
  partyTextGrey: '$partyTextGrey',
  partyMessagesContainer: '$partyMessagesContainer',
  partyGreenOnline: '$partyGreenOnline',
  partyOrangeAnonymous: '$partyOrangeAnonymous',
  partyRedCrush: '$partyRedCrush',
  partyGreyButtons: '$partyGreyButtons',
}

type ThemeChangeListener = () => unknown
const themeChangeListeners: ThemeChangeListener[] = []
function setOnThemeChangeListener(callback: ThemeChangeListener) {
  themeChangeListeners.push(callback)
}

export const ThemedStyleSheet = {
  ...StyleSheet,
  create<T extends StyleSheet.NamedStyles<T>>(styles: T): T {
    const themedStyles = {} as T
    const onThemeChange = () => {
      // @ts-expect-error we should fix the type errors
      const keys: (keyof T)[] = Object.keys(styles)
      keys.forEach(key => {
        themedStyles[key] = StyleSheet.create({ style: replaceStyleVariables(styles[key]) }).style
      })
    }
    setOnThemeChangeListener(onThemeChange)
    onThemeChange()

    return themedStyles
  },
}

export function changeTheme(newTheme: string) {
  if (activeTheme === newTheme) return
  if (newTheme !== 'light' && newTheme !== 'dark') return

  activeTheme = newTheme
  renderColors = replaceStyleVariables(stylesheetColors)
  setStatusBarStyle()
  setNavigationTheme()
  AsyncStorage.setItem('theme', newTheme)
  themeChangeListeners.forEach(cb => cb())
  reloadApp()
}

export let renderColors = replaceStyleVariables(stylesheetColors)

export const navigationTheme = getNavigationTheme()
function getNavigationTheme() {
  return {
    dark: activeTheme === 'dark',
    colors: {
      primary: renderColors.orange,
      background: renderColors.lightGrey,
      card: renderColors.white,
      text: renderColors.black,
      border: renderColors.grey,
      notification: renderColors.orange,
    },
  }
}
function setNavigationTheme() {
  const newTheme = getNavigationTheme()
  navigationTheme.dark = newTheme.dark
  navigationTheme.colors = newTheme.colors
}

setNavigationTheme()
setStatusBarStyle()

AsyncStorage.getItem('theme').then(theme => {
  if (theme) changeTheme(theme)
})
