import asyncStorage from './asyncStorage'

export let onboardingAB = 'none'
asyncStorage.getItem('onboardingAB2').then(res => {
  if (res === undefined) {
    const options = ['noregisterOrSignin2', 'registerOrSignin2']
    res = options[Math.floor(Math.random() * options.length)]
    asyncStorage.setItem('onboardingAB2', res)
  }
  onboardingAB = res
})
