import PropTypes from 'prop-types'
import React from 'react'
import { View, Text, SafeAreaView } from 'react-native'
import * as Sentry from 'sentry-expo'

import CCButton from './designSystem/CCButton'
import { ThemedStyleSheet } from '../colors'
import { t } from '../lib/i18n'
import { reloadApp } from '../lib/reloadApp'

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  }

  constructor(props) {
    super(props)
    this.state = { error: null, hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: true }
  }

  componentDidCatch(error) {
    Sentry.React.captureException(error)
    console.error(error)
  }

  resetError() {
    // this.setState({ error: null, hasError: false })
    reloadApp()
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessageDisplay error={this.state.error} resetError={this.resetError} />
    }

    return this.props.children
  }
}
export default ErrorBoundary

const ErrorMessageDisplay = ({ error, resetError }) => (
  <SafeAreaView style={styles.container}>
    <View style={styles.content}>
      <Text style={styles.title}>{t('Oops!')}</Text>
      <Text style={styles.subtitle}>{t("There's an error")}</Text>
      <Text style={styles.error}>{error.toString()}</Text>
      <CCButton title={t('Try again')} onPress={resetError} />
    </View>
  </SafeAreaView>
)
ErrorMessageDisplay.propTypes = {
  error: PropTypes.any,
  resetError: PropTypes.func,
}

const styles = ThemedStyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  content: {
    marginHorizontal: 16,
  },
  title: {
    fontSize: 48,
    fontWeight: '300',
    paddingBottom: 16,
  },
  subtitle: {
    fontSize: 32,
    fontWeight: '800',
  },
  error: {
    paddingVertical: 16,
  },
})
