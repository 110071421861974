import { type NavigationState } from '@react-navigation/routers/src/types'
import { getLastNotificationResponseAsync } from 'expo-notifications'
import { useEffect, useState } from 'react'
import { Platform } from 'react-native'

export const ccLinkingConfig = {
  screens: {
    Home: '',
    Login: 'login',
    Settings: 'settings',
    Inbox: 'inbox',
    Notifications: 'notifications',
    Earnings: 'earnings',
    Terms: 'terms',
    Privacy: 'privacy',
    Cookie: 'cookie-policy',
    Safety: 'safety',
    About: 'about',
    Profile: {
      path: ':username',
      parse: {
        username: decodeURIComponent,
      },
    },
    ProfilePost: {
      path: ':username/post/:postID',
      parse: {
        username: decodeURIComponent,
        postID: Number,
      },
    },
    ProfileEdit: {
      path: ':username/edit',
      parse: {
        username: decodeURIComponent,
      },
    },
  },
}

type InitialNavStateRoute = Omit<NavigationState['routes'][number], 'key'>
type InitialNavState = { routes: InitialNavStateRoute[] }

export default function useCCLinking() {
  const [isNavReady, setIsNavReady] = useState(false)
  const [initialNavState, setInitialNavState] = useState<InitialNavState>()

  useEffect(() => {
    async function loadInitialState() {
      // Load state from clicked push notification
      // If no clicked push notification is found, just leave it undefined for react-navigation to use the default

      if (Platform.OS === 'web') {
        return
      }

      const notificationResponse = await getLastNotificationResponseAsync()

      if (notificationResponse !== null) {
        let initialRoute: InitialNavStateRoute = {
          name: 'Home',
          params: undefined,
        }
        switch (notificationResponse.notification.request.content.data.type) {
          case 'NewQuestions': {
            initialRoute = { name: 'Inbox', params: undefined }
            break
          }
          case 'FirstNotification': {
            initialRoute = { name: 'Settings', params: undefined }
            break
          }
          case 'QuestionAnswered':
          case 'Follow': {
            initialRoute = { name: 'Notifications', params: undefined }
            break
          }
        }
        setInitialNavState({ routes: [initialRoute] })
      }
    }

    // Android fix for getInitialState not ever finishing, nor throwing: timeout after 1s
    const timeoutPromise = new Promise(resolve => setTimeout(resolve, 1000))
    Promise.race([loadInitialState(), timeoutPromise]).finally(() => {
      setIsNavReady(true)
    })
  })

  return {
    isNavReady,
    initialNavState,
  }
}
