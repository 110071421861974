import * as Font from 'expo-font'
import { Platform } from 'react-native'

import { setRouters } from '../Main'
import { refreshUserData } from '../lib/api'
import { loadUserData, userData } from '../lib/user'

export default function preloadResources() {
  loadWebAds()
  return Promise.all([loadRouters(), loadFonts()])
}

function loadRouters() {
  return Promise.all([
    import('../routers/LoggedInRouter').then(code => code.default),
    import('../routers/LoggedOutRouter').then(code => code.default),
  ])
    .then(routers => {
      setRouters(...routers)
    })
    .catch(error => {
      throw new Error('Error initializing loadRouters: ' + error.message, { cause: error })
    })
}

export async function preloadUser() {
  await loadUserData()
  if (userData) {
    await refreshUserData().catch(() => undefined)
  }
}

function loadFonts() {
  const robotoFonts = {
    'Roboto-Regular': require('../../assets/fonts/Roboto-Regular.ttf'),
    'Roboto-Bold': require('../../assets/fonts/Roboto-Bold.ttf'),
    'Roboto-BoldItalic': require('../../assets/fonts/Roboto-BoldItalic.ttf'),
    'Roboto-Italic': require('../../assets/fonts/Roboto-Italic.ttf'),
    'Roboto-Light': require('../../assets/fonts/Roboto-Light.ttf'),
    'Roboto-LightItalic': require('../../assets/fonts/Roboto-LightItalic.ttf'),
    'Roboto-Medium': require('../../assets/fonts/Roboto-Medium.ttf'),
    'Roboto-MediumItalic': require('../../assets/fonts/Roboto-MediumItalic.ttf'),
  }
  const metaFonts = {
    'MetaPro-Regular': require('../../assets/fonts/MetaPro.ttf'),
    'MetaPro-RegularItalic': require('../../assets/fonts/MetaPro-Italic.ttf'),
    'MetaPro-Black': require('../../assets/fonts/MetaPro-Black.ttf'),
    'MetaPro-BlackItalic': require('../../assets/fonts/MetaPro-BlackItalic.ttf'),
    'MetaPro-Bold': require('../../assets/fonts/MetaPro-Bold.ttf'),
    'MetaPro-BoldItalic': require('../../assets/fonts/MetaPro-BoldItalic.ttf'),
    'MetaPro-Light': require('../../assets/fonts/MetaPro-Light.ttf'),
    'MetaPro-LightItalic': require('../../assets/fonts/MetaPro-LightItalic.ttf'),
    'MetaPro-Medium': require('../../assets/fonts/MetaPro-Medium.ttf'),
    'MetaPro-MediumItalic': require('../../assets/fonts/MetaPro-MediumItalic.ttf'),
  }
  return Font.loadAsync({
    ...(Platform.OS === 'ios' ? {} : robotoFonts),
    ...metaFonts,
  }).catch(error => {
    console.error('Error loading fonts: ' + error.message)
    // Ignore fonts not loading error, and just go ahead with default fonts: Better than not loading at all
  })
}

function loadAdsense() {
  // Google adsense
  window.adsbygoogle = window.adsbygoogle || []
  webLoadScript(
    '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6833777094376847',
    'adsbygoogle',
    { crossorigin: 'anonymous' }
  )
}

function loadGpt() {
  webLoadScript('//securepubads.g.doubleclick.net/tag/js/gpt.js', 'gpt', {
    crossorigin: 'anonymous',
  })
  window.googletag = window.googletag || {}
  window.googletag.cmd = window.googletag.cmd || []

  webLoadScript('/prebid6.1.0.js', 'prebid', { crossorigin: 'anonymous' })
  window.pbjs = window.pbjs || {}
  window.pbjs.que = window.pbjs.que || []
}

function loadWebAds() {
  if (Platform.OS !== 'web') return

  const isAdsense = true
  // window.location.host === 'curiouscat.me' ||
  // window.location.host === 'curiouscat.live' ||
  // window.location.host === 'localhost:19006' ||
  // window.location.host === 'dev.curiouscat.me:19006' ||
  // window.location.host === 'dev.curiouscat.live:19006'
  if (isAdsense) {
    loadAdsense()
    loadGpt()
  } else {
    // Initialize adsbygoogle to avoid errors if ad tries to load before "external" bundle is loaded
    // Sortable also has trouble if this is not defined
    window.adsbygoogle = window.adsbygoogle || []
    webLoadScript('//tags-cdn.deployads.com/a/curiouscat.me.js', 'deployads-script')
  }
}

/**
  Helper function to insert async script tags
*/
function webLoadScript(url, id, extra) {
  if (document.getElementById(id)) return
  const js = document.createElement('script')
  if (!js) return
  js.id = id
  js.src = url
  js.async = 'async'
  if (extra) Object.assign(js, extra)
  document.body.appendChild(js)
}
