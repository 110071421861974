import { reloadApp } from './reloadApp'
import { clearUserData } from './user'
import store from '../store'
import { USER_LOGOUT } from '../store/actions/types'

export async function logout() {
  await clearUserData()
  setTimeout(() => {
    store.dispatch({ type: USER_LOGOUT })
    reloadApp()
  }, 1)
}
