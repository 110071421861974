export const USER_LOGOUT = 'USER_LOGOUT'

// Posts
export const ADD_POSTS = 'ADD_POSTS'
export const POST_DELETE_POST = 'POST_DELETE_POST'
export const POST_UPDATE = 'POST_UPDATE'

// Feed
export const FEED_FETCH_MORE = 'FEED_FETCH_MORE'
export const FEED_RESET = 'FEED_RESET'
export const FEED_DELETE_ITEM = 'FEED_DELETE_ITEM'

// Profiles
export const FETCH_PROFILE = 'FETCH_PROFILE'
export const RESET_PROFILE = 'RESET_PROFILE'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_DELETE_ITEM = 'PROFILE_DELETE_ITEM'

// Inbox
export const INBOX_FETCH_MORE_QUESTIONS = 'INBOX_FETCH_MORE_QUESTIONS'
export const INBOX_RESET_QUESTIONS = 'INBOX_RESET_QUESTIONS'
export const INBOX_DELETE_QUESTION = 'INBOX_DELETE_QUESTION'
