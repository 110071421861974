import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, TouchableOpacity } from 'react-native'

import { ThemedStyleSheet, stylesheetColors, renderColors } from '../../colors'
import AsyncStorage from '../../lib/asyncStorage'
import { registerForPushNotificationsAsync } from '../../lib/pushNotifications'
import { userData } from '../../lib/user'
import SvgIcon from '../SvgIcon'
import CCButton from '../designSystem/CCButton'
import CCFullscreenActivityIndicator from '../designSystem/CCFullscreenActivityIndicator'
import CCModal from '../modals/CCModal'

const UPDATE_INTERVAL = 1000 * 3600 * 24 * 7
const RETRY_INTERVAL = 1000 * 3600 * 24 * 2
const CONFIRM_INTERVAL = 1000 * 3600 * 24 * 14

async function registerForPushAsync() {
  try {
    await registerForPushNotificationsAsync()
    AsyncStorage.setItem('WebPushUpdateUntil', Date.now() + UPDATE_INTERVAL)
  } catch (err) {
    AsyncStorage.setItem('WebPushUpdateUntil', Date.now() + RETRY_INTERVAL)
    console.warn(err)
  }
}

export default function WebPushConfirmLayer() {
  const [t] = useTranslation()
  const [isVisible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const hasUser = !!userData
  const onRequestClose = useCallback(() => {
    setVisible(false)
  }, [])
  useEffect(() => {
    if (window.Notification && window.PushManager && userData) {
      if (window.Notification.permission === 'default') {
        AsyncStorage.getItem('DisableWebPushConfirmUntil').then(v => {
          if (!v || new Date(parseInt(v, 10)) < Date.now()) {
            setVisible(true)
          }
        })
      } else if (window.Notification.permission === 'granted') {
        AsyncStorage.getItem('WebPushUpdateUntil').then(v => {
          if (!v || new Date(parseInt(v, 10)) < Date.now()) {
            registerForPushAsync()
          }
        })
      }
    }
  }, [hasUser])
  if (!isVisible) return null
  return (
    <>
      {!loading && (
        <CCModal onRequestClose={onRequestClose}>
          <View style={styles.container}>
            <View style={styles.logoContainer}>
              <SvgIcon
                svg={require('./img/notifications.svg')}
                size={32}
                color={renderColors.orange}
                accessibilityLabel=""
              />
            </View>
            <View style={styles.textContainer}>
              <Text>{t('Want to know when you get new questions or answers?')}</Text>
              <View style={styles.buttonsContainer}>
                <CCButton
                  title={t('Yes, please!')}
                  type="orange"
                  style={styles.allowButton}
                  onPress={() => {
                    setLoading(true)
                    registerForPushAsync()
                      //.catch((err) => ccAlert(err.message))
                      .finally(() => onRequestClose())
                  }}
                />
                <TouchableOpacity
                  onPress={() => {
                    AsyncStorage.setItem('DisableWebPushConfirmUntil', Date.now() + CONFIRM_INTERVAL)
                    onRequestClose()
                  }}>
                  <Text style={styles.textNoThanks}>{t('No')}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </CCModal>
      )}
      <CCFullscreenActivityIndicator isOpen={loading} />
    </>
  )
}

const styles = ThemedStyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    alignSelf: 'center',
    backgroundColor: stylesheetColors.white,
    padding: 15,
    flexDirection: 'row',
    borderRadius: 2,
    // Shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    maxWidth: 400,
  },
  logoContainer: {
    padding: 9,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: stylesheetColors.grey,
    backgroundColor: stylesheetColors.lightGrey,
    marginRight: 10,
    alignSelf: 'start',
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  buttonsContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'row-reverse',
    marginTop: 20,
  },
  textNoThanks: {
    marginTop: 5,
    fontSize: 12,
    color: stylesheetColors.textLightGrey,
    borderBottomWidth: 1,
    borderBottomColor: stylesheetColors.textLightGrey,
  },
  allowButton: {
    paddingHorizontal: 15,
    borderRadius: 15,
    marginLeft: 15,
  },
})
