import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { USER_LOGOUT } from './actions/types'
import appReducer from './reducers'

const middleware = [thunk]
const initialState = {}

const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(applyMiddleware(...middleware))

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = initialState
  }

  return appReducer(state, action)
}

const store = createStore(rootReducer, initialState, enhancer)
export default store
