import * as Sentry from 'sentry-expo'

export function initSentry() {
  Sentry.init({
    dsn: 'https://54dd13204a1241a18077fa3cf5aa7632@o919494.ingest.sentry.io/5863669',
    enableInExpoDevelopment: true,
    debug: process.env.NODE_ENV === 'development',
    ignoreErrors: [
      // Temporarily disabled error until we can investigate and fix it:
      // https://grandcrew.sentry.io/issues/4058562265/?project=5863669&query=is%3Aunresolved&referrer=issue-stream&stream_index=2
      // Due to miscellaneous Google Ads errors on web
      'adsbygoogle.push() error',
    ],
  })
}
