import * as Device from 'expo-device'
import { updateId } from 'expo-updates'

export function getOTAUpdateId() {
  return updateId ?? 'ø'
}

export function getRuntimeVersion() {
  return process.env.BABEL_REPLACE_EXPO_RUNTIME_VERSION
}

export function getUserAgent() {
  return `CuriousCatApp/${getRuntimeVersion()}-${getOTAUpdateId()} (${Device.osName} ${Device.osVersion}; ${
    Device.brand
  }; ${Device.modelName})`
}
