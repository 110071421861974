import { openAuthSessionAsync } from 'expo-web-browser'
import qs from 'qs'
import { Platform } from 'react-native'

import api, { refreshUserData } from './api'
import AsyncStorage from './asyncStorage'
import { t, getCurrentLang } from './i18n'
import { registerForPushNotificationsAsync } from './pushNotifications'
import { reloadApp } from './reloadApp'
import { userData, saveUserData } from './user'
import { staticOpenTutorial } from '../components/tutorial/Tutorial'

const FB_APP_ID = '519586681583153'
const FB_PERMISSIONS = ['public_profile', 'user_friends']
const nativeLoginReturnUrl = 'curiouscat://'

export async function startLoginProcess(
  socialNetwork,
  setLoadingFn,
  { isLinking = false, email = '', magic_link = '' } = {}
) {
  try {
    setLoadingFn(true)
    if (isLinking && !userData) {
      throw new Error('Not logged in, unable to link to account')
    }

    if (socialNetwork === 'email::register') {
      const register = await requestMagicLink(email, true)
      return register
    }

    if (socialNetwork === 'email::login') {
      const login = await requestMagicLink(email, false)
      return login
    }

    if (socialNetwork === 'email::redeem') {
      const login = await redeemMagicLink(magic_link)
      return login
    }

    // We'll need to use a browser (Either THE browser in the web, or an embedded one in native)
    const { authUrl, extraData } = await getAuthUrlAndExtraData(socialNetwork, isLinking)
    const partialData = {
      islinking: isLinking,
      ...extraData,
    }

    if (Platform.OS === 'web') {
      // On web, the page is redirected and state is lost
      // TODO: find a way to not redirect?
      await AsyncStorage.setItem('login_process', JSON.stringify({ socialNetwork, partialData }))
      window.document.location = authUrl
      return
    }

    await nativeLoginWithBrowser(setLoadingFn, authUrl, socialNetwork, partialData)
  } catch (e) {
    throw e
  } finally {
    setLoadingFn(false)
  }
}

async function redeemMagicLink(magic_link) {
  const apiCall = await api.post(`/v2/login/email/redeem_magic_link`, { magic_link })
  if (apiCall.sessionkey) {
    // Save user info
    await saveUserData({
      session_id: apiCall.sessionkey,
    })
    // Load the remaining data (settings, lang, etc...)
    await refreshUserData()

    setTimeout(reloadApp, 1)
    if (Platform.OS !== 'web') {
      // Ask for notifications after logging in
      setTimeout(() => {
        registerForPushNotificationsAsync().catch(() => {
          /* Ignore error */
        })
      }, 2500)
    }
  } else if (apiCall.error) {
    throw new Error(apiCall.error)
  } else {
    throw new Error('Something went wrong...')
  }
}

async function requestMagicLink(email, isRegister) {
  const apiCall = await api.post(`/v2/login/email/${isRegister ? 'register' : 'request_magic_link'}`, {
    email,
  })

  if (apiCall.success) {
    return {
      success: true,
    }
  } else {
    throw new Error(apiCall.error)
  }
}

async function getAuthUrlAndExtraData(socialNetwork, isLinking) {
  const webLoginReturnUrl = window.location && `${window.location.origin}${isLinking ? '/settings' : ''}`
  const redirectUrl = Platform.OS === 'web' ? webLoginReturnUrl : nativeLoginReturnUrl
  const extraData = {}
  let authUrl

  if (socialNetwork === 'facebook') {
    authUrl =
      `https://www.facebook.com/v4.0/dialog/oauth?response_type=token` +
      `&client_id=${FB_APP_ID}` +
      `&redirect_uri=${encodeURIComponent(redirectUrl)}` +
      `&scope=${FB_PERMISSIONS.join(',')}`
    extraData.redirect_uri = redirectUrl
  } else if (socialNetwork === 'twitter') {
    const data = await api.get('/v2/login/twitter', {
      callback: redirectUrl,
    })
    if (!data.response || !data.response.oauth_token) {
      throw new Error(data.error || 'Error while tring to get oauth_token from twitter')
    }
    extraData.authkey = data.response.authkey
    authUrl = `https://api.twitter.com/oauth/authenticate?oauth_token=${data.response.oauth_token}`
  }
  return {
    authUrl,
    extraData,
  }
}

export async function loginSuccesful(socialNetwork, loginData) {
  loginData = { ...loginData, lang: getCurrentLang() }
  const endpointSNPath = socialNetwork === 'twitter' ? 'twitterconfirm' : socialNetwork
  const loginResult = await api.get(`/v2/login/${endpointSNPath}`, loginData)

  if (!loginResult.sessionkey) {
    throw new Error(loginResult.error || '[loginSuccesful] ' + t('Something went wrong'))
  }
  // Save user info
  await saveUserData({
    session_id: loginResult.sessionkey,
    ...loginResult.user,
  })
  // Load the remaining data (settings, lang, etc...)
  await refreshUserData()

  setTimeout(reloadApp, 1)
  if (Platform.OS !== 'web') {
    // Ask for notifications after logging in
    setTimeout(() => {
      registerForPushNotificationsAsync().catch(() => {
        /* Ignore error */
      })
    }, 2500)
  }

  if (loginResult.new_user) {
    setTimeout(() => staticOpenTutorial(), 200)
  }
}

async function nativeLoginWithBrowser(setLoadingFn, authUrl, socialNetwork, partialData) {
  // The loading UI stays behind the web activity in Android, but not in iOS.
  // Maybe we can experiment with keeping the loading state for android in the future?
  setLoadingFn(false)
  const result = await openAuthSessionAsync(authUrl, nativeLoginReturnUrl)
  if (result.type === 'dismiss' || result.type === 'cancel') {
    throw new Error(t('Login cancelled by the user'))
  }
  setLoadingFn(true)
  const { params, errorCode } = result.url ? parseUrl(result.url) : {}

  if (!params || errorCode) {
    throw new Error(
      t('Something went wrong while logging in with {{socialNetwork}}: {{errorMessage}}', {
        socialNetwork,
        errorMessage: errorCode,
      })
    )
  }

  const data = {
    ...params,
    ...partialData,
  }
  await loginSuccesful(socialNetwork, data)
}

function parseUrl(url) {
  const parts = url.split('#')
  const hash = parts[1]
  const partsWithoutHash = parts[0].split('?')
  const queryString = partsWithoutHash[partsWithoutHash.length - 1]

  // Get query string (?hello=world)
  const parsedSearch = qs.parse(queryString)

  // Pull errorCode off of params
  const { errorCode } = parsedSearch
  delete parsedSearch.errorCode

  // Get hash (#abc=example)
  let parsedHash = {}
  if (parts[1]) {
    parsedHash = qs.parse(hash)
  }

  // Merge search and hash
  const params = {
    ...parsedSearch,
    ...parsedHash,
  }

  return {
    errorCode,
    params,
  }
}
