import { useSelector } from 'react-redux'

import { FEED_FETCH_MORE, FEED_RESET, FEED_DELETE_ITEM } from '../actions/types'
import preparePostListItemForState from '../preparePostListItemForState'

const initialState = {
  maxTimestamp: '',
  items: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FEED_RESET: {
      const newPostListItems = action.items.map(preparePostListItemForState)

      const newState = Object.assign({}, state, {
        items: newPostListItems,
        maxTimestamp: Math.min(...newPostListItems.map(item => item.timestamp)),
      })
      return newState
    }
    case FEED_FETCH_MORE: {
      let newPostListItems = [...state.items, ...action.items.map(preparePostListItemForState)]
      newPostListItems = newPostListItems.filter(
        (item, index, arr) => arr.findIndex(_fp => _fp.key === item.key) === index
      )

      const newState = Object.assign({}, state, {
        items: newPostListItems,
        maxTimestamp: Math.min(...newPostListItems.map(item => item.timestamp)),
      })
      return newState
    }
    case FEED_DELETE_ITEM: {
      const newPostListItems = state.items.filter(item => {
        if (item.type === 'post' || item.type === 'shared_post') return item.postID !== action.postID
        return true
      })

      const newState = Object.assign({}, state, {
        items: newPostListItems,
      })
      return newState
    }
    default:
      return state
  }
}

export function useFeedItems() {
  return useSelector(state => state.feed.items)
}
