import api from './api'
import { t } from './i18n'

export async function registerForPushNotificationsAsync() {
  return await registerWeb()
}

async function registerWeb() {
  if (!window.Notification) {
    throw new Error(t("Your browser doesn't support push notifications, yet."))
  }

  await webLoadFirebase()
  const messaging = window.firebase.messaging()
  try {
    await messaging.requestPermission()
  } catch (err) {
    throw new Error(t('Unable to get permission to notify: {{error}}', { error: err.message }))
  }

  let currentToken
  try {
    currentToken = await messaging.getToken()
  } catch (err) {
    throw new Error(t('An error occurred while retrieving token: {{error}}', { error: err.message }))
  }
  if (!currentToken) {
    throw new Error(t('No Instance ID token available, request permission to generate one'))
  }

  await api.post('/v2/profile/gpn/add', { registration_id: `web:${currentToken}` })
}

function webLoadFirebase() {
  if (document.getElementById('firebaseScript')) return

  const firebaseScript = document.createElement('script')
  firebaseScript.id = 'firebaseScript'
  firebaseScript.type = 'text/javascript'
  firebaseScript.src = 'https://www.gstatic.com/firebasejs/4.6.0/firebase.js'
  firebaseScript.async = true
  document.body.appendChild(firebaseScript)

  return new Promise(resolve => {
    function waiter() {
      if (!window.firebase) {
        setTimeout(waiter, 200)
        return
      }
      // Initialize Firebase
      if (window.firebase.apps.length === 0) {
        const config = {
          apiKey: 'AIzaSyA191q3vZcEdexnttjzRzl3VpOeLEncGPI',
          authDomain: 'curious-cat-142413.firebaseapp.com',
          databaseURL: 'https://curious-cat-142413.firebaseio.com',
          projectId: 'curious-cat-142413',
          storageBucket: 'curious-cat-142413.appspot.com',
          messagingSenderId: '598133205099',
        }
        window.firebase.initializeApp(config)
      }
      resolve()
    }
    waiter()
  })
}
