import React from 'react'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View, TouchableOpacity, ViewStyle, GestureResponderEvent, TextStyle } from 'react-native'

import { renderColors, ThemedStyleSheet } from '../../colors'
import { goToProfile } from '../../lib/navUtils'
import SvgIcon from '../SvgIcon'

type CCUsernameProps = {
  user?: Record<string, unknown>
  textSize?: number
  isLink?: boolean
  onPress?: (e: GestureResponderEvent) => void
  style?: ViewStyle
  textStyle?: TextStyle
}
const CCUsername: FC<CCUsernameProps> = ({ user, textSize = 16, style, textStyle, onPress, isLink, ...props }) => {
  const [t] = useTranslation()

  const verifiedString = t('Verified')

  const Wrapper =
    isLink || onPress
      ? (touchableProps: object) => (
          <TouchableOpacity
            onPress={e => {
              if (isLink && user) {
                goToProfile(user.username)
              }
              if (onPress) {
                onPress(e)
              }
            }}
            {...touchableProps}
          />
        )
      : View

  return (
    <Wrapper {...props} style={[styles.container, style]}>
      <>
        <Text
          style={[styles.userUsername, { fontSize: textSize, lineHeight: textSize * 1.1 }, textStyle]}
          numberOfLines={1}>
          <>{user?.username || ' '}</>
        </Text>
        {user?.verified && (
          <SvgIcon
            style={{ marginLeft: textSize * 0.2 }}
            svg={require('./img/verified.svg')}
            size={textSize}
            color={renderColors.orange}
            accessibilityLabel={verifiedString}
          />
        )}
      </>
    </Wrapper>
  )
}

const styles = ThemedStyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  userUsername: {
    fontWeight: '500',
  },
})
export default CCUsername
